import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Snackbar,
  Alert
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const StatusEmpresa = () => {
  const [statuses, setStatuses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [message, setMessage] = useState('');
  const [currentStatus, setCurrentStatus] = useState(null);
  const [statusValue, setStatusValue] = useState('');
  const [deleteStatusId, setDeleteStatusId] = useState(null);

  useEffect(() => {
    fetchStatuses();
  }, []);

  const fetchStatuses = () => {
    fetch('https://www.irpratico.com.br/api/status_empresa_api.php?action=get')
      .then(response => response.json())
      .then(data => setStatuses(data))
      .catch(error => console.error('Erro ao buscar status:', error));
  };

  const handleShowModal = (status) => {
    setCurrentStatus(status);
    setStatusValue(status ? status.nome : '');
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentStatus(null);
    setStatusValue('');
  };

  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    fetchStatuses();
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('nome', statusValue);
    formData.append('action', currentStatus ? 'edit' : 'add');
    if (currentStatus) {
      formData.append('id', currentStatus.id);
    }

    try {
      const response = await fetch('https://www.irpratico.com.br/api/status_empresa_api.php', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        if (currentStatus) {
          setStatuses(statuses.map(status => (status.id === data.id ? data : status)));
          setMessage('Status editado com sucesso');
        } else {
          setStatuses([...statuses, data]);
          setMessage('Status adicionado com sucesso');
        }
        setShowMessageModal(true);
        handleCloseModal();
      } else {
        throw new Error(data.message || 'Erro ao salvar status');
      }
    } catch (error) {
      setMessage('Erro ao salvar status: ' + error.message);
      setShowMessageModal(true);
    }
  };

  const handleShowDeleteModal = (id) => {
    setDeleteStatusId(id);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    const formData = new FormData();
    formData.append('action', 'delete');
    formData.append('id', deleteStatusId);

    try {
      const response = await fetch('https://www.irpratico.com.br/api/status_empresa_api.php', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        setStatuses(statuses.filter(status => status.id !== deleteStatusId));
        setMessage('Status removido com sucesso');
        setShowMessageModal(true);
        setShowDeleteModal(false);
      } else {
        throw new Error(data.message || 'Erro ao remover status');
      }
    } catch (error) {
      setMessage('Erro ao remover status: ' + error.message);
      setShowMessageModal(true);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'nome', headerName: 'Nome', flex: 2 },
    {
      field: 'actions',
      headerName: 'Ações',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton color="primary" onClick={() => handleShowModal(params.row)}><EditIcon /></IconButton>
          <IconButton color="secondary" onClick={() => handleShowDeleteModal(params.row.id)}><DeleteIcon /></IconButton>
        </>
      )
    }
  ];

  return (
    <Box sx={{ padding: 4 }}>
      <h2>Status Empresa</h2>
      <Button variant="contained" color="primary" onClick={() => handleShowModal(null)} startIcon={<AddIcon />}>
        Adicionar Status
      </Button>
      <Box sx={{ height: 400, width: '100%', marginTop: 2 }}>
        <DataGrid
          rows={statuses}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </Box>

      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>{currentStatus ? 'Editar Status' : 'Adicionar Status'}</DialogTitle>
        <form onSubmit={handleSave}>
          <DialogContent>
            <TextField
              margin="dense"
              label="Nome"
              value={statusValue}
              onChange={(e) => setStatusValue(e.target.value)}
              fullWidth
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="secondary">Fechar</Button>
            <Button type="submit" color="primary">Salvar</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar open={showMessageModal} autoHideDuration={6000} onClose={handleCloseMessageModal}>
        <Alert onClose={handleCloseMessageModal} severity="success">{message}</Alert>
      </Snackbar>

      <Dialog open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <DialogTitle>Confirmação de Exclusão</DialogTitle>
        <DialogContent>Tem certeza que deseja remover este status?</DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteModal(false)} color="secondary">Cancelar</Button>
          <Button onClick={handleDelete} color="primary">Remover</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StatusEmpresa;
