import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Avatar, Box } from '@mui/material';
import { WhatsApp as WhatsAppIcon, ExitToApp as ExitToAppIcon, Info as InfoIcon, Person as PersonIcon } from '@mui/icons-material';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://node.irpratico.com.br/user_details', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.error) {
          throw new Error(data.error);
        }

        // Ajuste a URL da imagem de perfil
        if (data.profile_image) {
          data.profile_image = `https://node.irpratico.com.br/uploads/${data.profile_image}`;
        }

        setUser(data);
      } catch (error) {
        console.error('Erro ao buscar detalhes do usuário:', error.message);
      }
    };

    fetchUserDetails();
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <>
      <AppBar position="relative" sx={{ width: '100%', zIndex: 1100, height: '68px', backgroundColor: 'white', overflowX: 'hidden' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end', minHeight: '68px !important', height: '68px', overflowX: 'auto' }}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" sx={{ marginRight: 2, color: 'black' }}>
              {user ? `Olá, ${user.nome} (${user.nivel_acesso})` : 'Carregando...'}
            </Typography>
            <IconButton
              onClick={handleMenuClick}
              sx={{ padding: 0, marginLeft: 1, width: '40px', height: '40px' }}
            >
              <Avatar src={user?.profile_image} sx={{ width: '40px', height: '40px' }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem disabled>
                <PersonIcon sx={{ marginRight: 1 }} />
                {user?.nome}
              </MenuItem>
              <MenuItem onClick={() => window.location.href = '/perfil'}>
                <PersonIcon sx={{ marginRight: 1 }} />
                Perfil
              </MenuItem>
              <MenuItem onClick={() => window.location.href = '/suporte'}>
                <WhatsAppIcon sx={{ marginRight: 1 }} />
                Suporte
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ExitToAppIcon sx={{ marginRight: 1 }} />
                Sair
              </MenuItem>
              <MenuItem disabled>
                <InfoIcon sx={{ marginRight: 1 }} />
                Versão do Sistema: 1.0.0
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ height: '12px' }}></Box> {/* Espaço abaixo da barra */}
    </>
  );
};

export default Header;
