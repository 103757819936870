import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => (
  <Box 
    component="footer" 
    sx={{ 
      p: 2, 
      backgroundColor: 'transparent', 
      color: 'black', 
      textAlign: 'center', 
      width: '100%', 
      zIndex: 1, 
      display: 'flex',
      justifyContent: 'flex-end', 
      alignItems: 'center',
      position: 'relative', 
      height: '100px',
      mt: 4, 
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
      <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
        Versão do Sistema: 1.0.0
      </Typography>
      <img src="https://atalk.com.br/wp-content/uploads/2024/02/IRYD-LOGO.png.webp" alt="Iryd Sistemas Logo" style={{ width: '80px' }} />
    </Box>
  </Box>
);

export default Footer;
