import React, { useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Chip,
  Autocomplete,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import 'dayjs/locale/pt-br';

const KanbanTable = ({ tarefas, declarantes, statusAtividades, usuarios, updateTarefa, deleteTarefa }) => {
  const [editTarefa, setEditTarefa] = useState(null);
  const [filters, setFilters] = useState({
    declarante: [],
    status: [],
    statusAtividade: [],
    ano: '',
    dataCriacao: '',
    usuario: [],
  });

  const getDeclaranteNome = (idDeclarante) => {
    return declarantes.find(declarante => declarante.Id === idDeclarante)?.NomeDeclarante || 'Desconhecido';
  };

  const getUsuarioNome = (idUsuario) => {
    return usuarios.find(usuario => usuario.id === idUsuario)?.nome || 'Desconhecido';
  };

  const getStatusAtividadeDescricao = (idStatus) => {
    return statusAtividades.find(status => status.id_status === idStatus)?.descricao || 'Desconhecido';
  };

  const handleFilterChange = (event, value, name) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleEditClick = (tarefa) => {
    setEditTarefa(tarefa);
  };

  const handleDeleteClick = (id_tarefa) => {
    deleteTarefa(id_tarefa);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id_declarante',
        header: 'Declarante',
        Cell: ({ cell }) => getDeclaranteNome(cell.getValue()),
        filterVariant: 'multi-select',
        filterSelectOptions: declarantes.map(declarante => ({
          text: declarante.NomeDeclarante,
          value: declarante.Id,
        })),
        minSize: 100, // Largura mínima da coluna
      },
      {
        accessorKey: 'status_tarefa',
        header: 'Status',
        Cell: ({ cell }) => <Chip label={cell.getValue()} />,
        filterVariant: 'multi-select',
        filterSelectOptions: [...new Set(tarefas.map(tarefa => tarefa.status_tarefa))].map(status => ({
          text: status,
          value: status,
        })),
        minSize: 100, // Largura mínima da coluna
      },
      {
        accessorKey: 'status_trabalho',
        header: 'Status da Atividade',
        Cell: ({ cell }) => <Chip label={getStatusAtividadeDescricao(cell.getValue())} />,
        filterVariant: 'multi-select',
        filterSelectOptions: statusAtividades.map(status => ({
          text: status.descricao,
          value: status.id_status,
        })),
        minSize: 150, // Largura mínima da coluna
      },
      {
        accessorKey: 'data_exercicio',
        header: 'Ano Base',
        Cell: ({ cell }) => new Date(cell.getValue()).getFullYear(),
        filterVariant: 'text',
        minSize: 100, // Largura mínima da coluna
      },
      {
        accessorKey: 'data_criacao',
        header: 'Data de Criação',
        Cell: ({ cell }) => <><CalendarTodayIcon /> {new Date(cell.getValue()).toLocaleDateString('pt-BR')}</>,
        filterVariant: 'text',
        minSize: 150, // Largura mínima da coluna
      },
      {
        accessorKey: 'data_exercicio_full',
        header: 'Data de Exercício',
        Cell: ({ cell }) => <><CalendarTodayIcon /> {new Date(cell.getValue()).toLocaleDateString('pt-BR')}</>,
        filterVariant: 'text',
        minSize: 150, // Largura mínima da coluna
      },
      {
        accessorKey: 'data_selecionada',
        header: 'Data Selecionada',
        Cell: ({ cell }) => <><CalendarTodayIcon /> {new Date(cell.getValue()).toLocaleDateString('pt-BR')}</>,
        filterVariant: 'text',
        minSize: 150, // Largura mínima da coluna
      },
      {
        accessorKey: 'dia_entrega',
        header: 'Dia de Entrega',
        Cell: ({ cell }) => <><CalendarTodayIcon /> {new Date(cell.getValue()).toLocaleDateString('pt-BR')}</>,
        filterVariant: 'text',
        minSize: 150, // Largura mínima da coluna
      },
      {
        accessorKey: 'id_usuario',
        header: 'Usuário',
        Cell: ({ cell }) => getUsuarioNome(cell.getValue()),
        filterVariant: 'multi-select',
        filterSelectOptions: usuarios.map(usuario => ({
          text: usuario.nome,
          value: usuario.id,
        })),
        minSize: 150, // Largura mínima da coluna
      },
      {
        accessorKey: 'acoes',
        header: 'Ações',
        Cell: ({ row }) => (
          <>
            <Tooltip title="Editar">
              <IconButton onClick={() => handleEditClick(row.original)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Excluir">
              <IconButton onClick={() => handleDeleteClick(row.original.id_tarefa)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
        enableSorting: false,
        enableColumnFilter: false,
        minSize: 100, // Largura mínima da coluna
      },
    ],
    [declarantes, statusAtividades, usuarios]
  );

  const table = useMaterialReactTable({
    columns,
    data: tarefas,
    enableFacetedValues: true,
    initialState: { showColumnFilters: true },
    enableRowSelection: true,
    getRowId: (row) => row.id_tarefa,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableSelectAll: true,
    selectAllMode: 'page',
  });

  return (
    <Box sx={{ padding: 0 }}>
      <Typography variant="h6" gutterBottom>
        Tarefas em Lista
      </Typography>
      <Paper
        sx={{
          overflow: 'auto', // Permite rolagem automática
          width: '65%', 
          minWidth: 0,
          overflowX: 'auto', // Adiciona rolagem horizontal para colunas largas
        }}
      >
        <MaterialReactTable
          table={table}
          localization={{
            actions: 'Ações',
            showHideColumns: 'Mostrar/Esconder Colunas',
            search: 'Busca',
            sorting: 'Ordenar',
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              firstPage: 'Primeira Página',
              lastPage: 'Última Página',
              nextPage: 'Próxima Página',
              previousPage: 'Página Anterior',
            },
          }}
          muiTableProps={{
            sx: {
              width: '1100px', // Largura fixa maior para forçar a rolagem horizontal
              '& .MuiTableCell-root': {
                padding: '0px', // Reduz o padding das células
              },
            },
          }}
        />
      </Paper>
      {editTarefa && (
        <Modal
          open={Boolean(editTarefa)}
          onClose={() => setEditTarefa(null)}
        >
          <Box className="modal-style">
            <Typography variant="h6" component="h2">Editar Tarefa</Typography>
            <TextField label="Declarante" value={getDeclaranteNome(editTarefa.id_declarante)} fullWidth margin="normal" disabled />
            <TextField label="Status" value={editTarefa.status_tarefa} fullWidth margin="normal" disabled />
            <FormControl fullWidth margin="normal">
              <InputLabel>Status da Atividade</InputLabel>
              <Select
                value={editTarefa.status_trabalho}
                onChange={(e) => setEditTarefa({ ...editTarefa, status_trabalho: e.target.value })}
                label="Status da Atividade"
              >
                {statusAtividades.map(status => (
                  <MenuItem key={status.id_status} value={status.id_status}>
                    {status.descricao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Usuário</InputLabel>
              <Select
                value={editTarefa.id_usuario}
                onChange={(e) => setEditTarefa({ ...editTarefa, id_usuario: e.target.value })}
                label="Usuário"
              >
                {usuarios.map(usuario => (
                  <MenuItem key={usuario.id} value={usuario.id}>
                    {usuario.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              onClick={() => {
                updateTarefa(editTarefa.id_tarefa, editTarefa.status_tarefa, editTarefa.status_trabalho, editTarefa.id_usuario);
                setEditTarefa(null);
              }}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Salvar
            </Button>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default KanbanTable;
