import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Snackbar,
  Alert
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const Contabilidade = () => {
  const [escritorios, setEscritorios] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [message, setMessage] = useState('');
  const [currentEscritorio, setCurrentEscritorio] = useState(null);
  const [nome, setNome] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [responsavel, setResponsavel] = useState('');
  const [cidade, setCidade] = useState('');

  useEffect(() => {
    fetchEscritorios();
  }, []);

  const fetchEscritorios = () => {
    fetch('https://node.irpratico.com.br/escritorios', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => response.json())
      .then(data => setEscritorios(data))
      .catch(error => {
        setMessage('Erro ao buscar escritórios: ' + error.message);
        setShowMessageModal(true);
      });
  };

  const handleShowModal = (escritorio) => {
    setCurrentEscritorio(escritorio);
    setNome(escritorio ? escritorio.nome : '');
    setEmpresa(escritorio ? escritorio.empresa : '');
    setResponsavel(escritorio ? escritorio.responsavel : '');
    setCidade(escritorio ? escritorio.cidade : '');
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentEscritorio(null);
    setNome('');
    setEmpresa('');
    setResponsavel('');
    setCidade('');
  };

  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    fetchEscritorios(); // Fetch updated data after closing message modal
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const escritorioData = { nome, empresa, responsavel, cidade };
    try {
      let url = 'https://node.irpratico.com.br/escritorios';
      let method = 'POST';
      if (currentEscritorio) {
        url = `https://node.irpratico.com.br/${currentEscritorio.id}`;
        method = 'PUT';
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(escritorioData),
      });

      const data = await response.json();
      if (response.ok) {
        if (currentEscritorio) {
          setEscritorios(escritorios.map(e => (e.id === data.id ? data : e)));
          setMessage('Escritório editado com sucesso');
        } else {
          setEscritorios([...escritorios, data]);
          setMessage('Escritório adicionado com sucesso');
        }
        setShowMessageModal(true);
        handleCloseModal();
      } else {
        throw new Error(data.message || 'Erro ao salvar escritório');
      }
    } catch (error) {
      setMessage('Erro ao salvar escritório: ' + error.message);
      setShowMessageModal(true);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Tem certeza que deseja remover este escritório?')) {
      try {
        const response = await fetch(`https://node.irpratico.com.br/escritorios/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        const data = await response.json();
        if (response.ok) {
          setEscritorios(escritorios.filter(e => e.id !== id));
          setMessage('Escritório removido com sucesso');
          setShowMessageModal(true);
        } else {
          throw new Error(data.message || 'Erro ao remover escritório');
        }
      } catch (error) {
        setMessage('Erro ao remover escritório: ' + error.message);
        setShowMessageModal(true);
      }
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'nome', headerName: 'Nome', flex: 2 },
    { field: 'responsavel', headerName: 'Responsável', flex: 2 },
    { field: 'cidade', headerName: 'Cidade', flex: 2 },
    {
      field: 'actions',
      headerName: 'Ações',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton color="primary" onClick={() => handleShowModal(params.row)}><EditIcon /></IconButton>
          <IconButton color="secondary" onClick={() => handleDelete(params.row.id)}><DeleteIcon /></IconButton>
        </>
      )
    }
  ];

  return (
    <Box sx={{ padding: 4 }}>
      <h2>Contabilidade</h2>
      <Button variant="contained" color="primary" onClick={() => handleShowModal(null)}>
        <AddIcon /> Adicionar Escritório
      </Button>
      <Box sx={{ height: 400, width: '100%', marginTop: 2 }}>
        <DataGrid
          rows={escritorios}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </Box>

      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>{currentEscritorio ? 'Editar Escritório' : 'Adicionar Escritório'}</DialogTitle>
        <form onSubmit={handleSave}>
          <DialogContent>
            <TextField
              margin="dense"
              label="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              fullWidth
              required
            />
            
            <TextField
              margin="dense"
              label="Responsável"
              value={responsavel}
              onChange={(e) => setResponsavel(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="Cidade"
              value={cidade}
              onChange={(e) => setCidade(e.target.value)}
              fullWidth
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="secondary">Fechar</Button>
            <Button type="submit" color="primary">Salvar</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar open={showMessageModal} autoHideDuration={6000} onClose={handleCloseMessageModal}>
        <Alert onClose={handleCloseMessageModal} severity="success">{message}</Alert>
      </Snackbar>
    </Box>
  );
};

export default Contabilidade;
