import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Snackbar,
  Alert,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Visibility, VisibilityOff } from '@mui/icons-material';

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [message, setMessage] = useState('');
  const [messageSeverity, setMessageSeverity] = useState('success');
  const [currentUsuario, setCurrentUsuario] = useState(null);
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [nivelAcesso, setNivelAcesso] = useState('');

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const fetchUsuarios = () => {
    fetch('http://localhost:3001/api/usuarios', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setUsuarios(data);
        } else {
          throw new Error('A resposta da API não é um array');
        }
      })
      .catch(error => {
        setMessage('Erro ao buscar usuários: ' + error.message);
        setMessageSeverity('error');
        setShowMessageModal(true);
      });
  };

  const handleShowModal = (usuario) => {
    setCurrentUsuario(usuario);
    setNome(usuario ? usuario.nome : '');
    setTelefone(usuario ? usuario.telefone : '');
    setCpf(usuario ? usuario.cpf : '');
    setCnpj(usuario ? usuario.cnpj : '');
    setEmpresa(usuario ? usuario.empresa : '');
    setEmail(usuario ? usuario.email : '');
    setSenha(usuario ? usuario.senha : '');
    setNivelAcesso(usuario ? usuario.nivel_acesso : '');
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentUsuario(null);
    setNome('');
    setTelefone('');
    setCpf('');
    setCnpj('');
    setEmpresa('');
    setEmail('');
    setSenha('');
    setNivelAcesso('');
  };

  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    fetchUsuarios();
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const usuarioData = {
      nome,
      telefone,
      cpf,
      cnpj,
      empresa,
      email,
      senha,
      nivel_acesso: nivelAcesso
    };

    try {
      let url = 'http://localhost:3001/api/usuarios';
      const method = currentUsuario ? 'PUT' : 'POST';
      if (currentUsuario) {
        url = `http://localhost:3001/api/usuarios/${currentUsuario.id}`;
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(usuarioData),
      });

      if (!response.ok) {
        throw new Error((await response.json()).message || 'Erro ao salvar usuário');
      }

      const data = await response.json();
      if (currentUsuario) {
        setUsuarios(usuarios.map(u => (u.id === data.id ? data : u)));
        setMessage('Usuário editado com sucesso');
      } else {
        setUsuarios([...usuarios, data]);
        setMessage('Usuário adicionado com sucesso');
      }
      setMessageSeverity('success');
      setShowMessageModal(true);
      handleCloseModal();
    } catch (error) {
      setMessage('Erro ao salvar usuário: ' + error.message);
      setMessageSeverity('error');
      setShowMessageModal(true);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Tem certeza que deseja remover este usuário?')) {
      try {
        const response = await fetch(`http://localhost:3001/api/usuarios/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          throw new Error((await response.json()).message || 'Erro ao remover usuário');
        }

        setUsuarios(usuarios.filter(u => u.id !== id));
        setMessage('Usuário removido com sucesso');
        setMessageSeverity('success');
        setShowMessageModal(true);
      } catch (error) {
        setMessage('Erro ao remover usuário: ' + error.message);
        setMessageSeverity('error');
        setShowMessageModal(true);
      }
    }
  };

  const toggleMostrarSenha = () => {
    setMostrarSenha(!mostrarSenha);
  };

  return (
    <Box p={2}>
      <h2>Usuários</h2>
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => handleShowModal(null)}>
        Adicionar Usuário
      </Button>
      <Box mt={2} style={{ height: 400, width: '100%' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>CPF</TableCell>
                <TableCell>CNPJ</TableCell>
                <TableCell>Empresa</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Senha</TableCell>
                <TableCell>Nível de Acesso</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usuarios.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.nome}</TableCell>
                  <TableCell>{row.telefone}</TableCell>
                  <TableCell>{row.cpf}</TableCell>
                  <TableCell>{row.cnpj}</TableCell>
                  <TableCell>{row.empresa}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.senha}</TableCell>
                  <TableCell>{row.nivel_acesso}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleShowModal(row)}><EditIcon /></IconButton>
                    <IconButton color="secondary" onClick={() => handleDelete(row.id)}><DeleteIcon /></IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>{currentUsuario ? 'Editar Usuário' : 'Adicionar Usuário'}</DialogTitle>
        <form onSubmit={handleSave}>
          <DialogContent>
            <TextField
              margin="dense"
              label="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="Telefone"
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="CPF"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="CNPJ"
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="Empresa"
              value={empresa}
              onChange={(e) => setEmpresa(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="Senha"
              type={mostrarSenha ? 'text' : 'password'}
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleMostrarSenha}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Nível de Acesso</InputLabel>
              <Select
                value={nivelAcesso}
                onChange={(e) => setNivelAcesso(e.target.value)}
                required
              >
                <MenuItem value="">Selecione...</MenuItem>
                <MenuItem value="Administrador">Administrador</MenuItem>
                <MenuItem value="Gerente">Gerente</MenuItem>
                <MenuItem value="Usuario">Usuário</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="secondary">Fechar</Button>
            <Button type="submit" color="primary">Salvar</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar open={showMessageModal} autoHideDuration={6000} onClose={handleCloseMessageModal}>
        <Alert onClose={handleCloseMessageModal} severity={messageSeverity}>{message}</Alert>
      </Snackbar>
    </Box>
  );
};

export default Usuarios;
