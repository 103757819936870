import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Fab,
  Tooltip,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  IconButton,
  Alert,
  Menu,
  ListItemText,
  Paper,
} from '@mui/material';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { Add, Delete, Edit, FileDownload, Print, Settings, Visibility } from '@mui/icons-material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

const Empresa = () => {
  const [empresas, setEmpresas] = useState([]);
  const [statusEmpresas, setStatusEmpresas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentEmpresa, setCurrentEmpresa] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [empresaData, statusData] = await Promise.all([
        axios.get('https://www.irpratico.com.br/api/empresa.php?action=get_all', { withCredentials: true }),
        axios.get('https://www.irpratico.com.br/api/empresa.php?action=get_status_empresas', { withCredentials: true }),
      ]);

      setEmpresas(empresaData.data);
      setStatusEmpresas(statusData.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  const handleShowModal = (empresa) => {
    setCurrentEmpresa(empresa);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentEmpresa(null);
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleShowDeleteModal = (id) => {
    setCurrentEmpresa(id);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setCurrentEmpresa(null);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete('https://www.irpratico.com.br/api/empresa.php?action=delete', {
        data: { Id: currentEmpresa },
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      if (response.data.success) {
        const updatedEmpresas = empresas.filter((empresa) => empresa.Id !== currentEmpresa);
        setEmpresas(updatedEmpresas);
        setSnackbarMessage(response.data.message);
        setSnackbarOpen(true);
        handleCloseDeleteModal();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Erro ao remover empresa:', error);
    }
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    try {
      const response = await axios.post('https://www.irpratico.com.br/api/empresa.php?action=edit', formData, { withCredentials: true });

      const updatedEmpresas = empresas.map((empresa) => (empresa.Id === response.data.Id ? response.data : empresa));
      setEmpresas(updatedEmpresas);
      setSnackbarMessage(response.data.message);
      setSnackbarOpen(true);
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao editar empresa:', error);
    }
  };

  const handleAdd = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    try {
      const response = await axios.post('https://www.irpratico.com.br/api/empresa.php?action=add', formData, { withCredentials: true });

      setEmpresas([...empresas, response.data]);
      setSnackbarMessage(response.data.message);
      setSnackbarOpen(true);
      handleCloseAddModal();
    } catch (error) {
      console.error('Erro ao adicionar empresa:', error);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    const tableData = empresas.map((empresa) => ({
      Id: empresa.Id,
      'Código ERP': empresa.CodigoERP,
      CNPJ: empresa.cnpj,
      'Razão Social': empresa.RazaoSocial,
      Apelido: empresa.Apelido,
      Escritório: empresa.Escritorio,
      Celular: empresa.Celular,
      Email: empresa.Email,
      'Status Empresa': empresa.status_empresa,
    }));

    doc.autoTable({
      head: [['Id', 'Código ERP', 'CNPJ', 'Razão Social', 'Apelido', 'Escritório', 'Celular', 'Email', 'Status Empresa']],
      body: tableData.map((empresa) => Object.values(empresa)),
    });

    doc.save('empresas.pdf');
  };

  const handleExportExcel = () => {
    const tableData = empresas.map((empresa) => ({
      Id: empresa.Id,
      'Código ERP': empresa.CodigoERP,
      CNPJ: empresa.cnpj,
      'Razão Social': empresa.RazaoSocial,
      Apelido: empresa.Apelido,
      Escritório: empresa.Escritorio,
      Celular: empresa.Celular,
      Email: empresa.Email,
      'Status Empresa': empresa.status_empresa,
    }));

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Empresas');
    XLSX.writeFile(workbook, 'empresas.xlsx');
  };

  const handlePrint = () => {
    const printData = empresas.map((empresa) => ({
      Id: empresa.Id,
      'Código ERP': empresa.CodigoERP,
      CNPJ: empresa.cnpj,
      'Razão Social': empresa.RazaoSocial,
      Apelido: empresa.Apelido,
      Escritório: empresa.Escritorio,
      Celular: empresa.Celular,
      Email: empresa.Email,
      'Status Empresa': empresa.status_empresa,
    }));

    const printWindow = window.open('', '', 'height=400,width=800');
    printWindow.document.write('<html><head><title>Imprimir</title>');
    printWindow.document.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid black; padding: 8px; text-align: left; }</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write('<table><thead><tr>');
    printWindow.document.write('<th>Id</th><th>Código ERP</th><th>CNPJ</th><th>Razão Social</th><th>Apelido</th><th>Escritório</th><th>Celular</th><th>Email</th><th>Status Empresa</th>');
    printWindow.document.write('</tr></thead><tbody>');
    printData.forEach((row) => {
      printWindow.document.write('<tr>');
      Object.values(row).forEach((cell) => {
        printWindow.document.write(`<td>${cell}</td>`);
      });
      printWindow.document.write('</tr>');
    });
    printWindow.document.write('</tbody></table></body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const columns = useMemo(
    () => [
      { accessorKey: 'Id', header: 'ID' },
      { accessorKey: 'CodigoERP', header: 'Código ERP' },
      { accessorKey: 'cnpj', header: 'CNPJ' },
      { accessorKey: 'RazaoSocial', header: 'Razão Social' },
      { accessorKey: 'Apelido', header: 'Apelido' },
      { accessorKey: 'Escritorio', header: 'Escritório' },
      { accessorKey: 'Celular', header: 'Celular' },
      { accessorKey: 'Email', header: 'Email' },
      { accessorKey: 'status_empresa', header: 'Status Empresa' },
      {
        accessorKey: 'actions',
        header: 'Ações',
        Cell: ({ row }) => (
          <div>
            <Tooltip title="Visualizar">
              <IconButton color="primary" onClick={() => handleShowModal(row.original)}>
                <Visibility />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar">
              <IconButton color="secondary" onClick={() => handleShowModal(row.original)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remover">
              <IconButton color="error" onClick={() => handleShowDeleteModal(row.original.Id)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Empresa
      </Typography>
      <Paper  sx={{
          overflow: 'auto', // Permite rolagem automática
          width: '65%', 
          minWidth: 0,
          overflowX: 'auto', // Adiciona rolagem horizontal para colunas largas
        }}
      >
        <MaterialReactTable
          columns={columns}
          data={empresas}
          enableColumnOrdering
          localization={{
            actions: 'Ações',
            showHideColumns: 'Mostrar/Esconder Colunas',
            search: 'Busca',
            sorting: 'Ordenar',
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              firstPage: 'Primeira Página',
              lastPage: 'Última Página',
              nextPage: 'Próxima Página',
              previousPage: 'Página Anterior',
            },
          }}
        />
      </Paper>
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>Visualizar/Editar Empresa</DialogTitle>
        <form onSubmit={handleEdit}>
          <DialogContent>
            <TextField margin="dense" label="ID" name="Id" defaultValue={currentEmpresa ? currentEmpresa.Id : ''} fullWidth type="hidden" />
            <TextField margin="dense" label="Código ERP" name="CodigoERP" defaultValue={currentEmpresa ? currentEmpresa.CodigoERP : ''} fullWidth />
            <TextField margin="dense" label="CNPJ" name="cnpj" defaultValue={currentEmpresa ? currentEmpresa.cnpj : ''} fullWidth />
            <TextField margin="dense" label="Razão Social" name="RazaoSocial" defaultValue={currentEmpresa ? currentEmpresa.RazaoSocial : ''} fullWidth />
            <TextField margin="dense" label="Apelido" name="Apelido" defaultValue={currentEmpresa ? currentEmpresa.Apelido : ''} fullWidth />
            <TextField margin="dense" label="Escritório" name="Escritorio" defaultValue={currentEmpresa ? currentEmpresa.Escritorio : ''} fullWidth />
            <TextField margin="dense" label="Celular" name="Celular" defaultValue={currentEmpresa ? currentEmpresa.Celular : ''} fullWidth />
            <TextField margin="dense" label="Email" name="Email" defaultValue={currentEmpresa ? currentEmpresa.Email : ''} fullWidth />
            <FormControl fullWidth margin="dense">
              <InputLabel>Status Empresa</InputLabel>
              <Select
                label="Status Empresa"
                name="status_empresa"
                defaultValue={currentEmpresa ? currentEmpresa.status_empresa : ''}
                required
              >
                {statusEmpresas.map((status) => (
                  <MenuItem key={status.Id} value={status.nome}>
                    {status.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="secondary">Fechar</Button>
            <Button type="submit" color="primary">Salvar</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={showAddModal} onClose={handleCloseAddModal}>
        <DialogTitle>Adicionar Empresa</DialogTitle>
        <form onSubmit={handleAdd}>
          <DialogContent>
            <TextField margin="dense" label="Código ERP" name="CodigoERP" fullWidth />
            <TextField margin="dense" label="CNPJ" name="cnpj" fullWidth />
            <TextField margin="dense" label="Razão Social" name="RazaoSocial" fullWidth />
            <TextField margin="dense" label="Apelido" name="Apelido" fullWidth />
            <TextField margin="dense" label="Escritório" name="Escritorio" fullWidth />
            <TextField margin="dense" label="Celular" name="Celular" fullWidth />
            <TextField margin="dense" label="Email" name="Email" fullWidth />
            <FormControl fullWidth margin="dense">
              <InputLabel>Status Empresa</InputLabel>
              <Select label="Status Empresa" name="status_empresa" required>
                {statusEmpresas.map((status) => (
                  <MenuItem key={status.Id} value={status.nome}>
                    {status.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddModal} color="secondary">Fechar</Button>
            <Button type="submit" color="primary">Adicionar</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={showDeleteModal} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>Tem certeza que deseja excluir esta empresa?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="secondary">Cancelar</Button>
          <Button onClick={handleDelete} color="primary">Excluir</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Tooltip title="Exportar e Configurações">
        <Fab color="primary" aria-label="settings" onClick={handleMenuOpen} style={{ position: 'fixed', bottom: 16, right: 16 }}>
          <Settings />
        </Fab>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => { handleExportPDF(); handleMenuClose(); }}>
          <FileDownload fontSize="small" />
          <ListItemText primary="Exportar PDF" />
        </MenuItem>
        <MenuItem onClick={() => { handleExportExcel(); handleMenuClose(); }}>
          <FileDownload fontSize="small" />
          <ListItemText primary="Exportar Excel" />
        </MenuItem>
        <MenuItem onClick={() => { handlePrint(); handleMenuClose(); }}>
          <Print fontSize="small" />
          <ListItemText primary="Imprimir" />
        </MenuItem>
        <MenuItem onClick={() => { handleShowAddModal(); handleMenuClose(); }}>
          <Add fontSize="small" />
          <ListItemText primary="Adicionar Empresa" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Empresa;
