import React from 'react';

const AdminDashboard = () => {
  return (
    <div className="dashboard-container">
      <h1>Dashboard do Administrador</h1>
      <p>Bem-vindo ao painel de controle do administrador.</p>
    </div>
  );
};

export default AdminDashboard;
