import React, { useMemo, useState, useEffect } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
  Snackbar, Tooltip, Typography, Select, FormControl, InputLabel, MenuItem,
  Checkbox, Fab, IconButton, ListItemText, Paper, Menu
} from '@mui/material';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Add, Delete, Edit, FileDownload, Print, Settings, Assignment } from '@mui/icons-material';
import './GerarTarefas.css';

const GerarTarefas = () => {
  const [dataSelecionada, setDataSelecionada] = useState('');
  const [diaEntrega, setDiaEntrega] = useState('');
  const [dataExercicio, setDataExercicio] = useState('');
  const [atividadeOptions, setAtividadeOptions] = useState([]);
  const [kanbanOptions, setKanbanOptions] = useState([]);
  const [prazosOptions, setPrazosOptions] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [declarantes, setDeclarantes] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [declaranteData, atividadeData, kanbanData, prazosData] = await Promise.all([
        axios.get('https://www.irpratico.com.br/api/gerar.php?action=get_all'),
        axios.get('https://www.irpratico.com.br/api/gerar.php?action=get_atividade_options'),
        axios.get('https://www.irpratico.com.br/api/gerar.php?action=get_kanban_options'),
        axios.get('https://www.irpratico.com.br/api/gerar.php?action=get_prazos_options')
      ]);

      setDeclarantes(declaranteData.data);
      setAtividadeOptions(atividadeData.data);
      setKanbanOptions(kanbanData.data);
      setPrazosOptions(prazosData.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  const fetchPrazos = async (dataExercicio) => {
    try {
      const { data } = await axios.get(`https://www.irpratico.com.br/api/gerar.php?action=get_prazos_options`);
      const filteredData = data.filter(item => item.data_exercicio === dataExercicio);
      if (filteredData.length > 0) {
        setDataSelecionada(filteredData[0].data_selecionada);
        setDiaEntrega(filteredData[0].dia_entrega);
      } else {
        setDataSelecionada('');
        setDiaEntrega('');
      }
    } catch (error) {
      console.error('Erro ao buscar prazos:', error);
    }
  };

  const handleSelectRow = (id) => {
    setSelectedTasks(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(taskId => taskId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAllRows = (rows) => {
    const rowIds = rows.map(row => row.original.Id);
    setSelectedTasks(rowIds);
  };

  const handleClearSelection = () => {
    setSelectedTasks([]);
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    const tableData = declarantes.map(row => ({
      Id: row.Id,
      'Código Declarante': row.CodigoDeclarante,
      CPF: row.CPF,
      Nome: row.NomeDeclarante,
      Status: row.StatusDeclarante,
      Responsável: row.Responsavel,
      Sócio: row.SocioEmpresa,
      DIRPF: row.EntregarDIRPF
    }));

    doc.autoTable({
      head: [['Id', 'Código Declarante', 'CPF', 'Nome', 'Status', 'Responsável', 'Sócio', 'DIRPF']],
      body: tableData.map(item => Object.values(item))
    });

    doc.save('declarantes.pdf');
  };

  const handleExportExcel = () => {
    const tableData = declarantes.map(row => ({
      Id: row.Id,
      'Código Declarante': row.CodigoDeclarante,
      CPF: row.CPF,
      Nome: row.NomeDeclarante,
      Status: row.StatusDeclarante,
      Responsável: row.Responsavel,
      Sócio: row.SocioEmpresa,
      DIRPF: row.EntregarDIRPF
    }));

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Declarantes');
    XLSX.writeFile(workbook, 'declarantes.xlsx');
  };

  const handlePrint = () => {
    const printData = declarantes.map(row => ({
      Id: row.Id,
      'Código Declarante': row.CodigoDeclarante,
      CPF: row.CPF,
      Nome: row.NomeDeclarante,
      Status: row.StatusDeclarante,
      Responsável: row.Responsavel,
      Sócio: row.SocioEmpresa,
      DIRPF: row.EntregarDIRPF
    }));

    const printWindow = window.open('', '', 'height=400,width=800');
    printWindow.document.write('<html><head><title>Imprimir</title>');
    printWindow.document.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid black; padding: 8px; text-align: left; }</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write('<table><thead><tr>');
    printWindow.document.write('<th>Id</th><th>Código Declarante</th><th>CPF</th><th>Nome</th><th>Status</th><th>Responsável</th><th>Sócio</th><th>DIRPF</th>');
    printWindow.document.write('</tr></thead><tbody>');
    printData.forEach(row => {
      printWindow.document.write('<tr>');
      Object.values(row).forEach(cell => {
        printWindow.document.write(`<td>${cell}</td>`);
      });
      printWindow.document.write('</tr>');
    });
    printWindow.document.write('</tbody></table></body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const handleGenerateTasks = async () => {
    try {
      const response = await axios.post('https://www.irpratico.com.br/api/gerar.php?action=generate_tasks', {
        id_declarantes: selectedTasks.join(','),
        data_criacao: new Date().toISOString().slice(0, 19).replace('T', ' '),
        data_conclusao: dataSelecionada,
        status_tarefa: statusFilter,
        dia_entrega: diaEntrega,
        atividade: statusFilter,
        kanban: userFilter,
        data_exercicio: dataExercicio
      });
      setConfirmationMessage(response.data.message);
      setIsSnackbarOpen(true);
      setIsModalOpen(false);
      fetchData();
    } catch (error) {
      setConfirmationMessage('Erro ao gerar tarefas');
      setIsSnackbarOpen(true);
      console.error('Erro ao gerar tarefas:', error);
    }
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const columns = useMemo(
    () => [
      { accessorKey: 'Id', header: 'Id', filterVariant: 'text' },
      { accessorKey: 'CodigoDeclarante', header: 'Código Declarante', filterVariant: 'text' },
      { accessorKey: 'CPF', header: 'CPF', filterVariant: 'text' },
      { accessorKey: 'NomeDeclarante', header: 'Nome', filterVariant: 'text' },
      { accessorKey: 'StatusDeclarante', header: 'Status', filterVariant: 'multi-select' },
      { accessorKey: 'Responsavel', header: 'Responsável', filterVariant: 'multi-select' },
      { accessorKey: 'SocioEmpresa', header: 'Sócio', filterVariant: 'multi-select' },
      { accessorKey: 'EntregarDIRPF', header: 'DIRPF', filterVariant: 'multi-select' },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: declarantes,
    enableFacetedValues: true,
    initialState: { showColumnFilters: true },
    enableRowSelection: true,
    getRowId: (row) => row.Id,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableSelectAll: true,
    selectAllMode: 'page',
    onRowSelectionChange: handleSelectRow,
    onSelectAllChange: handleSelectAllRows,
  });

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Gerar Tarefas
      </Typography>
      <Paper  sx={{
          overflow: 'auto', // Permite rolagem automática
          width: '78%', 
          minWidth: 0,
          overflowX: 'auto', // Adiciona rolagem horizontal para colunas largas
        }}
      >
        <MaterialReactTable
          table={table}
          renderTopToolbarCustomActions={() => (
            <Box sx={{ display: 'flex', gap: '0.5rem', p: '0.5rem' }}>
              <Button
                color="primary"
                onClick={() => handleSelectAllRows(table.getCoreRowModel().rows)}
                variant="contained"
              >
                Selecionar Todos
              </Button>
              <Button
                color="secondary"
                onClick={handleClearSelection}
                variant="contained"
              >
                Limpar Seleção
              </Button>
            </Box>
          )}
          localization={{
            actions: 'Ações',
            showHideColumns: 'Mostrar/Esconder Colunas',
            search: 'Busca',
            sorting: 'Ordenar',
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              firstPage: 'Primeira Página',
              lastPage: 'Última Página',
              nextPage: 'Próxima Página',
              previousPage: 'Página Anterior',
            },
          }}
        />
      </Paper>
      <div style={{ marginTop: '10px' }}>
        <Typography variant="body1">
          Total de declarantes: {declarantes.length} | Declarantes exibidos: {declarantes.length} | Declarantes selecionados: {selectedTasks.length}
        </Typography>
      </div>

      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Gerar Tarefas</DialogTitle>
        <DialogContent>
          <TextField
            label="Ano Base"
            select
            fullWidth
            value={dataExercicio}
            onChange={(e) => {
              setDataExercicio(e.target.value);
              fetchPrazos(e.target.value);
            }}
            margin="normal"
          >
            {prazosOptions.map((option) => (
              <MenuItem key={option.data_exercicio} value={option.data_exercicio}>
                {option.data_exercicio}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Data Selecionada"
            type="date"
            fullWidth
            value={dataSelecionada}
            onChange={(e) => setDataSelecionada(e.target.value)}
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Dia de Entrega"
            type="date"
            fullWidth
            value={diaEntrega}
            onChange={(e) => setDiaEntrega(e.target.value)}
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Atividade</InputLabel>
            <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <MenuItem value="">Selecione</MenuItem>
              {atividadeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Status do Kanban</InputLabel>
            <Select value={userFilter} onChange={(e) => setUserFilter(e.target.value)}>
              <MenuItem value="">Selecione</MenuItem>
              {kanbanOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Quantidade de IDs Selecionados"
            fullWidth
            value={selectedTasks.length}
            margin="normal"
            InputProps={{ readOnly: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)} color="secondary">Cancelar</Button>
          <Button onClick={handleGenerateTasks} color="primary">Gerar</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={confirmationMessage}
      />

      <Tooltip title="Exportar e Configurações">
        <Fab
          color="primary"
          aria-label="settings"
          onClick={handleMenuOpen}
          style={{ position: 'fixed', bottom: 16, right: 16 }}
        >
          <Settings />
        </Fab>
      </Tooltip>

      <Tooltip title="Gerar Tarefas">
        <Fab
          color="secondary"
          aria-label="gerar"
          onClick={() => setIsModalOpen(true)}
          style={{ position: 'fixed', bottom: 80, right: 16 }}
        >
          <Assignment />
        </Fab>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => { handleExportPDF(); handleMenuClose(); }}>
          <FileDownload fontSize="small" />
          <ListItemText primary="Exportar PDF" />
        </MenuItem>
        <MenuItem onClick={() => { handleExportExcel(); handleMenuClose(); }}>
          <FileDownload fontSize="small" />
          <ListItemText primary="Exportar Excel" />
        </MenuItem>
        <MenuItem onClick={() => { handlePrint(); handleMenuClose(); }}>
          <Print fontSize="small" />
          <ListItemText primary="Imprimir" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default GerarTarefas;
