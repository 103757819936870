import React, { useEffect, useState } from 'react';
import {
  Card, CardContent, Typography, Box, Grid, Paper, IconButton, Button,
  Drawer, FormControlLabel, Checkbox, FormControl, Menu, MenuItem, Avatar,
  TextField, Snackbar, Alert, Select
} from '@mui/material';
import {
  Settings as SettingsIcon, FilterList as FilterListIcon, MoreVert as MoreVertIcon, BarChart as BarChartIcon,
  PieChart as PieChartIcon, LineStyle as LineStyleIcon, DonutLarge as DonutLargeIcon, AccountBalanceWallet as WalletIcon,
  ShoppingCart as ShoppingCartIcon
} from '@mui/icons-material';
import {
  Bar, Pie, Line, Doughnut
} from 'react-chartjs-2';
import {
  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,
  ArcElement, LineElement, PointElement
} from 'chart.js';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import './Dashboard.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, LineElement, PointElement);

const Dashboard = () => {
  const [data, setData] = useState({
    total_registros_escritorio: 0,
    total_registros_empresas: 0,
    total_registros_usuarios: 0,
    total_registros_declarante: 0,
    status_declarante: [],
    entregar_dirpf: [],
    socio_empresa: [],
    opcoes_socio: [],
    tarefas_usuario: [],
    tarefas_criacao: [],
    status_tarefa: [],
    status_trabalho: [],
    quantidade_por_contabilidade: [],
    quantidade_socio: [],
    quantidade_por_empresas: [],
    declarante_socio: [],
    status_kanban: [],
    ano_base: []
  });

  const [displaySettings, setDisplaySettings] = useState({
    showEscritorio: true,
    showEmpresas: true,
    showUsuarios: true,
    showDeclarante: true,
    showStatusDeclarante: true,
    showEntregarDIRPF: true,
    showSocioEmpresa: true,
    showOpcoesSocio: true,
    showTarefasUsuario: true,
    showTarefasCriacao: true,
    showStatusTarefa: true,
    showStatusTrabalho: true,
    showQuantidadePorContabilidade: true,
    showQuantidadeSocio: true,
    showQuantidadePorEmpresas: true,
    showDeclaranteSocio: true,
    showStatusKanban: true,
    showAnoBase: true
  });

  const [chartTypes, setChartTypes] = useState({
    statusDeclarante: 'bar',
    entregarDIRPF: 'bar',
    socioEmpresa: 'bar',
    opcoesSocio: 'bar',
    tarefasUsuario: 'bar',
    tarefasCriacao: 'bar',
    statusTarefa: 'bar',
    statusTrabalho: 'bar',
    quantidadePorContabilidade: 'bar',
    quantidadeSocio: 'bar',
    quantidadePorEmpresas: 'bar',
    declaranteSocio: 'bar',
    statusKanban: 'bar',
    anoBase: 'bar'
  });

  const [localTime, setLocalTime] = useState(new Date());
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filterParams, setFilterParams] = useState({
    startDate: '',
    endDate: '',
    userFilter: '',
    companyFilter: '',
    declarantFilter: '',
    predefinedRange: ''
  });
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const predefinedRanges = [
    { label: '7 dias', value: '7d' },
    { label: '15 dias', value: '15d' },
    { label: '30 dias', value: '30d' },
    { label: '45 dias', value: '45d' },
    { label: '60 dias', value: '60d' },
    { label: '90 dias', value: '90d' },
    { label: '6 meses', value: '6m' },
    { label: '1 ano', value: '1y' },
  ];

  const fetchData = (params = '') => {
    fetch(`https://www.irpratico.com.br/api/dash_controle.php?${params}`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setData(data.data || {});
      })
      .catch(error => console.error('Erro ao buscar dados do dashboard:', error));
  };

  useEffect(() => {
    fetchData();
    loadPreferences();
  }, []);

  const loadPreferences = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://node.irpratico.com.br/get_preferences', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Erro ao carregar preferências');
      }

      const data = await response.json();
      setDisplaySettings(data.displaySettings);
      setChartTypes(data.chartTypes);
    } catch (error) {
      console.error('Erro ao carregar preferências:', error.message);
    }
  };

  const savePreferences = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://node.irpratico.com.br/save_preferences', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ displaySettings, chartTypes })
      });

      if (!response.ok) {
        throw new Error('Erro ao salvar preferências');
      }

      setMessage('Preferências salvas com sucesso');
      setShowMessage(true);
    } catch (error) {
      console.error('Erro ao salvar preferências:', error.message);
      setMessage('Erro ao salvar preferências: ' + error.message);
      setShowMessage(true);
    }
  };

  const applyPredefinedRange = (range) => {
    const endDate = new Date();
    let startDate = new Date();
    switch (range) {
      case '7d':
        startDate.setDate(endDate.getDate() - 7);
        break;
      case '15d':
        startDate.setDate(endDate.getDate() - 15);
        break;
      case '30d':
        startDate.setDate(endDate.getDate() - 30);
        break;
      case '45d':
        startDate.setDate(endDate.getDate() - 45);
        break;
      case '60d':
        startDate.setDate(endDate.getDate() - 60);
        break;
      case '90d':
        startDate.setDate(endDate.getDate() - 90);
        break;
      case '6m':
        startDate.setMonth(endDate.getMonth() - 6);
        break;
      case '1y':
        startDate.setFullYear(endDate.getFullYear() - 1);
        break;
      default:
        return;
    }
    setFilterParams(prevState => ({
      ...prevState,
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
      predefinedRange: range
    }));
    fetchData(new URLSearchParams({
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0]
    }).toString());
  };

  const socioEmpresaData = data.socio_empresa ? {
    labels: ['Sim', 'Não'],
    datasets: [
      {
        label: 'Socio Empresa',
        data: [
          data.socio_empresa.find(item => item.status === 'Sim')?.total || 0,
          data.socio_empresa.find(item => item.status === 'Não')?.total || 0,
        ],
        backgroundColor: ['#3f51b5', '#ff8c00'],
      },
    ],
  } : {};

  const statusDeclaranteData = data.status_declarante ? {
    labels: ['Ativo', 'Inativo'],
    datasets: [
      {
        label: 'Status do Declarante',
        data: [
          data.status_declarante.find(item => item.status === 'Ativo')?.total || 0,
          data.status_declarante.find(item => item.status === 'Inativo')?.total || 0,
        ],
        backgroundColor: ['#4caf50', '#f44336'],
      },
    ],
  } : {};

  const entregarDIRPFData = data.entregar_dirpf ? {
    labels: ['Sim', 'Não'],
    datasets: [
      {
        label: 'Entregar DIRPF',
        data: [
          data.entregar_dirpf.find(item => item.status === 'Sim')?.total || 0,
          data.entregar_dirpf.find(item => item.status === 'Não')?.total || 0,
        ],
        backgroundColor: ['#3f51b5', '#ff8c00'],
      },
    ],
  } : {};

  const opcoesSocioData = data.opcoes_socio ? {
    labels: ['Sim', 'Não'],
    datasets: [
      {
        label: 'Opções Sócio',
        data: [
          data.opcoes_socio.find(item => item.status === 'Sim')?.total || 0,
          data.opcoes_socio.find(item => item.status === 'Não')?.total || 0,
        ],
        backgroundColor: ['#3f51b5', '#ff8c00'],
      },
    ],
  } : {};

  const tarefasUsuarioData = data.tarefas_usuario ? {
    labels: data.tarefas_usuario.map(item => item.nome),
    datasets: [
      {
        label: 'Tarefas por Usuário',
        data: data.tarefas_usuario.map(item => item.total),
        backgroundColor: ['#3f51b5', '#ff8c00', '#4caf50', '#f44336', '#2196f3', '#ffeb3b'],
      },
    ],
  } : {};

  const tarefasCriacaoData = data.tarefas_criacao ? {
    labels: data.tarefas_criacao.map(item => item.data),
    datasets: [
      {
        label: 'Tarefas por Data de Criação',
        data: data.tarefas_criacao.map(item => item.total),
        backgroundColor: ['#3f51b5', '#ff8c00', '#4caf50', '#f44336', '#2196f3', '#ffeb3b'],
      },
    ],
  } : {};

  const statusTarefaData = data.status_tarefa ? {
    labels: data.status_tarefa.map(item => item.status),
    datasets: [
      {
        label: 'Status das Tarefas',
        data: data.status_tarefa.map(item => item.total),
        backgroundColor: ['#2196f3', '#ffeb3b'],
      },
    ],
  } : {};

  const statusTrabalhoData = data.status_trabalho ? {
    labels: data.status_trabalho.map(item => item.status),
    datasets: [
      {
        label: 'Status do Trabalho',
        data: data.status_trabalho.map(item => item.total),
        backgroundColor: ['#3f51b5', '#ff8c00'],
      },
    ],
  } : {};

  const quantidadePorContabilidadeData = data.quantidade_por_contabilidade ? {
    labels: data.quantidade_por_contabilidade.map(item => item.contabilidade),
    datasets: [
      {
        label: 'Quantidade por Contabilidade',
        data: data.quantidade_por_contabilidade.map(item => item.total),
        backgroundColor: ['#3f51b5', '#ff8c00', '#4caf50', '#f44336', '#2196f3', '#ffeb3b'],
      },
    ],
  } : {};

  const quantidadeSocioData = data.quantidade_socio ? {
    labels: ['Sim', 'Não'],
    datasets: [
      {
        label: 'Quantidade de Sócios',
        data: [
          data.quantidade_socio.find(item => item.socio === 'Sim')?.total || 0,
          data.quantidade_socio.find(item => item.socio === 'Não')?.total || 0,
        ],
        backgroundColor: ['#3f51b5', '#ff8c00'],
      },
    ],
  } : {};

  const quantidadePorEmpresasData = data.quantidade_por_empresas ? {
    labels: data.quantidade_por_empresas.map(item => item.empresa),
    datasets: [
      {
        label: 'Quantidade por Empresas',
        data: data.quantidade_por_empresas.map(item => item.total),
        backgroundColor: ['#3f51b5', '#ff8c00', '#4caf50', '#f44336', '#2196f3', '#ffeb3b'],
      },
    ],
  } : {};

  const declaranteSocioData = data.declarante_socio ? {
    labels: ['Sim', 'Não'],
    datasets: [
      {
        label: 'Declarante é Sócio',
        data: [
          data.declarante_socio.find(item => item.status === 'Sim')?.total || 0,
          data.declarante_socio.find(item => item.status === 'Não')?.total || 0,
        ],
        backgroundColor: ['#3f51b5', '#ff8c00'],
      },
    ],
  } : {};

  const statusKanbanData = data.status_kanban ? {
    labels: data.status_kanban.map(item => item.status),
    datasets: [
      {
        label: 'Status Kanban',
        data: data.status_kanban.map(item => item.total),
        backgroundColor: ['#3f51b5', '#ff8c00', '#4caf50', '#f44336', '#2196f3', '#ffeb3b'],
      },
    ],
  } : {};

  const anoBaseData = data.ano_base ? {
    labels: data.ano_base.map(item => item.ano),
    datasets: [
      {
        label: 'Ano Base',
        data: data.ano_base.map(item => item.total),
        backgroundColor: ['#3f51b5', '#ff8c00', '#4caf50', '#f44336', '#2196f3', '#ffeb3b'],
      },
    ],
  } : {};

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setDisplaySettings((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleChartTypeChange = (chartName, type) => {
    setChartTypes((prevState) => ({
      ...prevState,
      [chartName]: type,
    }));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleApplyFilter = () => {
    const params = new URLSearchParams(filterParams).toString();
    fetchData(params);
  };

  const toggleSettingsDrawer = () => {
    setSettingsOpen(!settingsOpen);
  };

  const toggleFiltersDrawer = () => {
    setFiltersOpen(!filtersOpen);
  };

  const renderChart = (data, chartName) => {
    const chartProps = {
      data: data,
      options: options,
    };

    const chartStyles = {
      height: '300px',
      width: '100%',
    };

    switch (chartTypes[chartName]) {
      case 'bar':
        return <Bar {...chartProps} style={chartStyles} />;
      case 'pie':
        return <Pie {...chartProps} style={chartStyles} />;
      case 'line':
        return <Line {...chartProps} style={chartStyles} />;
      case 'doughnut':
        return <Doughnut {...chartProps} style={chartStyles} />;
      default:
        return null;
    }
  };

  const renderInfoCard = (title, value, icon) => (
    <Grid item xs={12} sm={6} md={3}>
      <Card className="info-card">
        <CardContent>
          <Box display="flex" alignItems="center">
            <Avatar className="info-icon">{icon}</Avatar>
            <Box ml={2}>
              <Typography variant="h6" className="info-title">{title}</Typography>
              <Typography variant="h6" className="info-value">{value}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );

  const renderChartCard = (title, data, chartName) => (
    <Grid item xs={12} md={6}>
      <Paper className="chart-container" style={{ padding: '16px', height: '450px', position: 'relative' }}>
        <Typography variant="h6" color="textPrimary">{title}</Typography>
        {data && Object.keys(data).length > 0 && renderChart(data, chartName)}
        <PopupState variant="popover" popupId={`${chartName}-popup-menu`}>
          {(popupState) => (
            <>
              <IconButton
                {...bindTrigger(popupState)}
                style={{ position: 'absolute', top: '8px', right: '8px' }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => handleChartTypeChange(chartName, 'bar')}><BarChartIcon />Bar</MenuItem>
                <MenuItem onClick={() => handleChartTypeChange(chartName, 'pie')}><PieChartIcon />Pie</MenuItem>
                <MenuItem onClick={() => handleChartTypeChange(chartName, 'line')}><LineStyleIcon />Line</MenuItem>
                <MenuItem onClick={() => handleChartTypeChange(chartName, 'doughnut')}><DonutLargeIcon />Doughnut</MenuItem>
              </Menu>
            </>
          )}
        </PopupState>
      </Paper>
    </Grid>
  );

  return (
    <div className="dashboard">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box display="flex" alignItems="center">
          <TextField
            label="Data de Início"
            type="date"
            name="startDate"
            value={filterParams.startDate}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
            style={{ marginRight: '16px' }}
          />
          <TextField
            label="Data de Fim"
            type="date"
            name="endDate"
            value={filterParams.endDate}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
            style={{ marginRight: '16px' }}
          />
          <Select
            label="Período Pré-definido"
            name="predefinedRange"
            value={filterParams.predefinedRange}
            onChange={(e) => applyPredefinedRange(e.target.value)}
            displayEmpty
            style={{ marginRight: '16px', minWidth: '150px' }}
          >
            <MenuItem value="">Selecione um período</MenuItem>
            {predefinedRanges.map(range => (
              <MenuItem key={range.value} value={range.value}>
                {range.label}
              </MenuItem>
            ))}
          </Select>
          <Button variant="contained" color="primary" onClick={handleApplyFilter}>
            Aplicar Filtros
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton onClick={toggleSettingsDrawer}>
            <SettingsIcon />
          </IconButton>
          <IconButton onClick={toggleFiltersDrawer}>
            <FilterListIcon />
          </IconButton>
          <Button variant="contained" color="primary" onClick={savePreferences}>
            Salvar Preferências
          </Button>
        </Box>
      </Box>
      <Grid container spacing={3}>
        {displaySettings.showEscritorio && renderInfoCard('Total Escritórios', data.total_registros_escritorio, <ShoppingCartIcon />)}
        {displaySettings.showEmpresas && renderInfoCard('Total Empresas', data.total_registros_empresas, <ShoppingCartIcon />)}
        {displaySettings.showUsuarios && renderInfoCard('Total Usuários', data.total_registros_usuarios, <WalletIcon />)}
        {displaySettings.showDeclarante && renderInfoCard('Total Declarantes', data.total_registros_declarante, <WalletIcon />)}
        {displaySettings.showStatusDeclarante && renderChartCard('Status Declarante', statusDeclaranteData, 'statusDeclarante')}
        {displaySettings.showEntregarDIRPF && renderChartCard('Entregar DIRPF', entregarDIRPFData, 'entregarDIRPF')}
        {displaySettings.showSocioEmpresa && renderChartCard('Sócio Empresa', socioEmpresaData, 'socioEmpresa')}
        {displaySettings.showOpcoesSocio && renderChartCard('Opções Sócio', opcoesSocioData, 'opcoesSocio')}
        {displaySettings.showTarefasUsuario && renderChartCard('Tarefas por Usuário', tarefasUsuarioData, 'tarefasUsuario')}
        {displaySettings.showTarefasCriacao && renderChartCard('Tarefas por Data de Criação', tarefasCriacaoData, 'tarefasCriacao')}
        {displaySettings.showStatusTarefa && renderChartCard('Status Tarefas', statusTarefaData, 'statusTarefa')}
        {displaySettings.showStatusTrabalho && renderChartCard('Status Trabalho', statusTrabalhoData, 'statusTrabalho')}
        {displaySettings.showQuantidadePorContabilidade && renderChartCard('Quantidade por Contabilidade', quantidadePorContabilidadeData, 'quantidadePorContabilidade')}
        {displaySettings.showQuantidadeSocio && renderChartCard('Quantidade de Sócios', quantidadeSocioData, 'quantidadeSocio')}
        {displaySettings.showQuantidadePorEmpresas && renderChartCard('Quantidade por Empresas', quantidadePorEmpresasData, 'quantidadePorEmpresas')}
        {displaySettings.showDeclaranteSocio && renderChartCard('Declarante é Sócio', declaranteSocioData, 'declaranteSocio')}
        {displaySettings.showStatusKanban && renderChartCard('Status Kanban', statusKanbanData, 'statusKanban')}
        {displaySettings.showAnoBase && renderChartCard('Ano Base', anoBaseData, 'anoBase')}
      </Grid>
      <Drawer anchor="right" open={settingsOpen} onClose={toggleSettingsDrawer}>
        <Box p={2} width={300}>
          <Typography variant="h6">Configurações de Exibição</Typography>
          <FormControl component="fieldset">
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showEscritorio} onChange={handleCheckboxChange} name="showEscritorio" />}
              label="Mostrar Total de Escritórios"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showEmpresas} onChange={handleCheckboxChange} name="showEmpresas" />}
              label="Mostrar Total de Empresas"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showUsuarios} onChange={handleCheckboxChange} name="showUsuarios" />}
              label="Mostrar Total de Usuários"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showDeclarante} onChange={handleCheckboxChange} name="showDeclarante" />}
              label="Mostrar Total de Declarantes"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showStatusDeclarante} onChange={handleCheckboxChange} name="showStatusDeclarante" />}
              label="Mostrar Status do Declarante"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showEntregarDIRPF} onChange={handleCheckboxChange} name="showEntregarDIRPF" />}
              label="Mostrar Entregar DIRPF"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showSocioEmpresa} onChange={handleCheckboxChange} name="showSocioEmpresa" />}
              label="Mostrar Sócio Empresa"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showOpcoesSocio} onChange={handleCheckboxChange} name="showOpcoesSocio" />}
              label="Mostrar Opções Sócio"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showTarefasUsuario} onChange={handleCheckboxChange} name="showTarefasUsuario" />}
              label="Mostrar Tarefas por Usuário"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showTarefasCriacao} onChange={handleCheckboxChange} name="showTarefasCriacao" />}
              label="Mostrar Tarefas por Data de Criação"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showStatusTarefa} onChange={handleCheckboxChange} name="showStatusTarefa" />}
              label="Mostrar Status das Tarefas"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showStatusTrabalho} onChange={handleCheckboxChange} name="showStatusTrabalho" />}
              label="Mostrar Status do Trabalho"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showQuantidadePorContabilidade} onChange={handleCheckboxChange} name="showQuantidadePorContabilidade" />}
              label="Mostrar Quantidade por Contabilidade"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showQuantidadeSocio} onChange={handleCheckboxChange} name="showQuantidadeSocio" />}
              label="Mostrar Quantidade de Sócios"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showQuantidadePorEmpresas} onChange={handleCheckboxChange} name="showQuantidadePorEmpresas" />}
              label="Mostrar Quantidade por Empresas"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showDeclaranteSocio} onChange={handleCheckboxChange} name="showDeclaranteSocio" />}
              label="Mostrar Declarante é Sócio"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showStatusKanban} onChange={handleCheckboxChange} name="showStatusKanban" />}
              label="Mostrar Status Kanban"
            />
            <FormControlLabel
              control={<Checkbox checked={displaySettings.showAnoBase} onChange={handleCheckboxChange} name="showAnoBase" />}
              label="Mostrar Ano Base"
            />
          </FormControl>
          <Button variant="contained" color="primary" onClick={savePreferences} fullWidth style={{ marginTop: '16px' }}>
            Salvar Preferências
          </Button>
        </Box>
      </Drawer>
      <Drawer anchor="left" open={filtersOpen} onClose={toggleFiltersDrawer}>
        <Box p={2} width={300}>
          <Typography variant="h6">Filtros</Typography>
          <TextField
            label="Data de Início"
            type="date"
            name="startDate"
            value={filterParams.startDate}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Data de Fim"
            type="date"
            name="endDate"
            value={filterParams.endDate}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Usuário"
            name="userFilter"
            value={filterParams.userFilter}
            onChange={handleFilterChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Empresa"
            name="companyFilter"
            value={filterParams.companyFilter}
            onChange={handleFilterChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Declarante"
            name="declarantFilter"
            value={filterParams.declarantFilter}
            onChange={handleFilterChange}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyFilter}
            fullWidth
            style={{ marginTop: '16px' }}
          >
            Aplicar Filtros
          </Button>
        </Box>
      </Drawer>
      <Snackbar open={showMessage} autoHideDuration={6000} onClose={() => setShowMessage(false)}>
        <Alert onClose={() => setShowMessage(false)} severity="success">{message}</Alert>
      </Snackbar>
    </div>
  );
};

export default Dashboard;
