import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Snackbar,
  Alert,
  Avatar,
  IconButton,
  InputAdornment,
  Container,
  Typography,
  Grid,
  Divider
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot
} from '@mui/lab';

const Perfil = () => {
  const [usuario, setUsuario] = useState(null);
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [senha, setSenha] = useState('');
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [atividades, setAtividades] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    fetch('https://node.irpratico.com.br/user_details', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
      .then(response => response.json())
      .then(data => {
        setUsuario(data);
        setNome(data.nome || '');
        setTelefone(data.telefone || '');
        setCpf(data.cpf || '');
        setCnpj(data.cnpj || '');
        setEmpresa(data.empresa || '');
        setProfileImageUrl(data.profile_image ? `https://node.irpratico.com.br/uploads/${data.profile_image}` : '');
        
        // Fetch atividades
        fetch(`https://node.irpratico.com.br/perfil_get_atividades`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        })
        .then(response => response.json())
        .then(atividadesData => {
          setAtividades(atividadesData);
        })
        .catch(error => {
          setMessage('Erro ao buscar atividades: ' + error.message);
          setShowMessage(true);
        });

      })
      .catch(error => {
        setMessage('Erro ao buscar usuário: ' + error.message);
        setShowMessage(true);
      });
  }, []);

  const handleSave = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('nome', nome);
    formData.append('telefone', telefone);
    formData.append('cpf', cpf);
    formData.append('cnpj', cnpj);
    formData.append('empresa', empresa);
    if (senha) formData.append('senha', senha);
    if (profileImage) formData.append('profile_image', profileImage);

    const token = localStorage.getItem('token');

    try {
      const response = await fetch('https://node.irpratico.com.br/perfil_update_usuario', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        setMessage('Usuário atualizado com sucesso');
        setProfileImageUrl(data.profile_image ? `https://node.irpratico.com.br/uploads/${data.profile_image}` : profileImageUrl);
      } else {
        throw new Error(data.message || 'Erro ao atualizar usuário');
      }
      setShowMessage(true);
    } catch (error) {
      setMessage('Erro ao atualizar usuário: ' + error.message);
      setShowMessage(true);
    }
  };

  const toggleMostrarSenha = () => {
    setMostrarSenha(!mostrarSenha);
  };

  const handleProfileImageChange = (event) => {
    setProfileImage(event.target.files[0]);
    setProfileImageUrl(URL.createObjectURL(event.target.files[0]));
  };

  if (!usuario) return null;

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Perfil do Usuário
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
          <Avatar src={profileImageUrl} alt="Profile Image" sx={{ width: 100, height: 100, mb: 2 }} />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleProfileImageChange}
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span">
              Upload Foto de Perfil
            </Button>
          </label>
        </Box>
        <form onSubmit={handleSave}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Telefone"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="CPF"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="CNPJ"
                value={cnpj}
                onChange={(e) => setCnpj(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Empresa"
                value={empresa}
                onChange={(e) => setEmpresa(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Senha"
                type={mostrarSenha ? 'text' : 'password'}
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleMostrarSenha}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Button type="submit" variant="contained" color="primary">
              Salvar
            </Button>
          </Box>
        </form>
        <Snackbar open={showMessage} autoHideDuration={6000} onClose={() => setShowMessage(false)}>
          <Alert onClose={() => setShowMessage(false)} severity="success">{message}</Alert>
        </Snackbar>
        <Divider sx={{ my: 4 }} />
        <Typography variant="h5" gutterBottom>
          Histórico de Atividades
        </Typography>
        <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
          <Timeline position="alternate">
            {atividades.map((atividade, index) => (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  {index < atividades.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography>{atividade.descricao}</Typography>
                  <Typography variant="body2" color="textSecondary">{atividade.data}</Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>
      </Box>
    </Container>
  );
};

export default Perfil;
