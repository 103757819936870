import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Tooltip,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  IconButton,
  Paper,
  Alert,
  Menu,
  ListItemText,
  Container,
  Fab,
} from '@mui/material';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { Add, Delete, Edit, FileDownload, Print, Settings, Visibility } from '@mui/icons-material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import './Declarante.css';

const Declarante = () => {
  const [declarantes, setDeclarantes] = useState([]);
  const [statusDeclarantes, setStatusDeclarantes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentDeclarante, setCurrentDeclarante] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token'); // Obtém o token do localStorage

      const response = await axios.get('http://node.irpratico.com.br/declarante?action=get_declarantes', {
        headers: {
          'Authorization': `Bearer ${token}`, // Adiciona o token no cabeçalho da solicitação
        },
      });

      if (!response.status === 200) {
        throw new Error('Erro ao buscar dados, status: ' + response.status);
      }

      const declarantesRes = response.data;
      setDeclarantes(Array.isArray(declarantesRes) ? declarantesRes : []);

      setStatusDeclarantes(await axios.get('http://node.irpratico.com.br/declarante?action=get_status', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      }).then((res) => res.data));
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  const handleShowModal = (declarante, isView = false) => {
    setCurrentDeclarante({ ...declarante, isView });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentDeclarante(null);
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleShowDeleteModal = (id) => {
    setCurrentDeclarante({ DeclaranteId: id });
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setCurrentDeclarante(null);
  };

  const handleDelete = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.delete('http://node.irpratico.com.br/declarante', {
        data: { Id: currentDeclarante.DeclaranteId },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        const updatedDeclarantes = declarantes.filter((declarante) => declarante.DeclaranteId !== currentDeclarante.DeclaranteId);
        setDeclarantes(updatedDeclarantes);
        setSnackbarMessage(response.data.message);
        setSnackbarOpen(true);
        handleCloseDeleteModal();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Erro ao remover declarante:', error);
    }
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post('http://node.irpratico.com.br/declarante', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        const updatedDeclarantes = declarantes.map((declarante) =>
          declarante.DeclaranteId === response.data.DeclaranteId ? response.data : declarante
        );
        setDeclarantes(updatedDeclarantes);
        setSnackbarMessage(response.data.message);
        setSnackbarOpen(true);
        handleCloseModal();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Erro ao editar declarante:', error);
    }
  };

  const handleAdd = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post('http://node.irpratico.com.br/declarante', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        setDeclarantes([...declarantes, response.data]);
        setSnackbarMessage(response.data.message);
        setSnackbarOpen(true);
        handleCloseAddModal();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Erro ao adicionar declarante:', error);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    const tableData = declarantes.map((declarante) => ({
      Id: declarante.DeclaranteId,
      'Código Declarante': declarante.CodigoDeclarante,
      CPF: declarante.CPF,
      Nome: declarante.NomeDeclarante,
      Celular: declarante.DeclaranteCelular,
      Email: declarante.DeclaranteEmail,
      'Status Declarante': declarante.StatusDeclarante,
      'Entregar DIRPF': declarante.EntregarDIRPF,
      'Sócio Empresa': declarante.SocioEmpresa,
      'Opções Sócio': declarante.DeclaranteOpcoesSocio,
      'Contabilidade Declarante': declarante.ContabilidadeDeclarante,
      Usuários: declarante.usuarios,
      'Data de Nascimento': declarante.dataNascimento,
      'Id Empresa': declarante.EmpresaId,
      'Código ERP': declarante.CodigoERP,
      'Nome Empresa': declarante.EmpresaNome,
      'Nome Sócio': declarante.NomeSocio,
    }));

    doc.autoTable({
      head: [
        [
          'Id',
          'Código Declarante',
          'CPF',
          'Nome',
          'Celular',
          'Email',
          'Status Declarante',
          'Entregar DIRPF',
          'Sócio Empresa',
          'Opções Sócio',
          'Contabilidade Declarante',
          'Usuários',
          'Data de Nascimento',
          'Id Empresa',
          'Código ERP',
          'Nome Empresa',
          'Nome Sócio',
        ],
      ],
      body: tableData.map((declarante) => Object.values(declarante)),
    });

    doc.save('declarantes.pdf');
  };

  const handleExportExcel = () => {
    const tableData = declarantes.map((declarante) => ({
      Id: declarante.DeclaranteId,
      'Código Declarante': declarante.CodigoDeclarante,
      CPF: declarante.CPF,
      Nome: declarante.NomeDeclarante,
      Celular: declarante.DeclaranteCelular,
      Email: declarante.DeclaranteEmail,
      'Status Declarante': declarante.StatusDeclarante,
      'Entregar DIRPF': declarante.EntregarDIRPF,
      'Sócio Empresa': declarante.SocioEmpresa,
      'Opções Sócio': declarante.DeclaranteOpcoesSocio,
      'Contabilidade Declarante': declarante.ContabilidadeDeclarante,
      Usuários: declarante.usuarios,
      'Data de Nascimento': declarante.dataNascimento,
      'Id Empresa': declarante.EmpresaId,
      'Código ERP': declarante.CodigoERP,
      'Nome Empresa': declarante.EmpresaNome,
      'Nome Sócio': declarante.NomeSocio,
    }));

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Declarantes');
    XLSX.writeFile(workbook, 'declarantes.xlsx');
  };

  const handlePrint = () => {
    const printData = declarantes.map((declarante) => ({
      Id: declarante.DeclaranteId,
      'Código Declarante': declarante.CodigoDeclarante,
      CPF: declarante.CPF,
      Nome: declarante.NomeDeclarante,
      Celular: declarante.DeclaranteCelular,
      Email: declarante.DeclaranteEmail,
      'Status Declarante': declarante.StatusDeclarante,
      'Entregar DIRPF': declarante.EntregarDIRPF,
      'Sócio Empresa': declarante.SocioEmpresa,
      'Opções Sócio': declarante.DeclaranteOpcoesSocio,
      'Contabilidade Declarante': declarante.ContabilidadeDeclarante,
      Usuários: declarante.usuarios,
      'Data de Nascimento': declarante.dataNascimento,
      'Id Empresa': declarante.EmpresaId,
      'Código ERP': declarante.CodigoERP,
      'Nome Empresa': declarante.EmpresaNome,
      'Nome Sócio': declarante.NomeSocio,
    }));

    const printWindow = window.open('', '', 'height=400,width=800');
    printWindow.document.write('<html><head><title>Imprimir</title>');
    printWindow.document.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid black; padding: 8px; text-align: left; }</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write('<table><thead><tr>');
    printWindow.document.write(
      '<th>Id</th><th>Código Declarante</th><th>CPF</th><th>Nome</th><th>Celular</th><th>Email</th><th>Status Declarante</th><th>Entregar DIRPF</th><th>Sócio Empresa</th><th>Opções Sócio</th><th>Contabilidade Declarante</th><th>Usuários</th><th>Data de Nascimento</th><th>Id Empresa</th><th>Código ERP</th><th>Nome Empresa</th><th>Nome Sócio</th>'
    );
    printWindow.document.write('</tr></thead><tbody>');
    printData.forEach((row) => {
      printWindow.document.write('<tr>');
      Object.values(row).forEach((cell) => {
        printWindow.document.write(`<td>${cell}</td>`);
      });
      printWindow.document.write('</tr>');
    });
    printWindow.document.write('</tbody></table></body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const columns = useMemo(
    () => [
      { accessorKey: 'DeclaranteId', header: 'Id' },
      { accessorKey: 'CodigoDeclarante', header: 'Código Declarante' },
      { accessorKey: 'CPF', header: 'CPF' },
      { accessorKey: 'NomeDeclarante', header: 'Nome' },
      { accessorKey: 'DeclaranteCelular', header: 'Celular' },
      { accessorKey: 'DeclaranteEmail', header: 'Email' },
      { accessorKey: 'StatusDeclarante', header: 'Status Declarante' },
      { accessorKey: 'EntregarDIRPF', header: 'Entregar DIRPF' },
      { accessorKey: 'SocioEmpresa', header: 'Sócio Empresa' },
      { accessorKey: 'DeclaranteOpcoesSocio', header: 'Opções Sócio' },
      { accessorKey: 'ContabilidadeDeclarante', header: 'Contabilidade Declarante' },
      { accessorKey: 'usuarios', header: 'Usuários' },
      { accessorKey: 'dataNascimento', header: 'Data de Nascimento' },
      { accessorKey: 'EmpresaId', header: 'Id Empresa' },
      { accessorKey: 'CodigoERP', header: 'Código ERP' },
      { accessorKey: 'EmpresaNome', header: 'Nome Empresa' },
      { accessorKey: 'NomeSocio', header: 'Nome Sócio' },
      {
        accessorKey: 'Acoes',
        header: 'Ações',
        Cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            <Tooltip title="Visualizar">
              <IconButton color="primary" onClick={() => handleShowModal(row.original, true)}>
                <Visibility />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar">
              <IconButton color="secondary" onClick={() => handleShowModal(row.original)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remover">
              <IconButton color="error" onClick={() => handleShowDeleteModal(row.original.DeclaranteId)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Container     className="declarante-container">
      <Typography variant="h4" component="h2" gutterBottom>
        Declarante
      </Typography>
      <Paper  className="paper-content"
      >
        <MaterialReactTable
          columns={columns}
          data={declarantes}
          enableColumnOrdering
          enableColumnResizing
          localization={{
            actions: 'Ações',
            showHideColumns: 'Mostrar/Esconder Colunas',
            search: 'Busca',
            sorting: 'Ordenar',
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              firstPage: 'Primeira Página',
              lastPage: 'Última Página',
              nextPage: 'Próxima Página',
              previousPage: 'Página Anterior',
            },
          }}
          muiTableProps={{
            sx: {
              '& .MuiTableContainer-root': {
                maxHeight: 500,
              },
            },
          }}
        />
      </Paper>
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>{currentDeclarante?.isView ? 'Visualizar Declarante' : 'Editar Declarante'}</DialogTitle>
        <form onSubmit={handleEdit}>
          <DialogContent>
            <TextField
              margin="dense"
              label="ID"
              name="DeclaranteId"
              defaultValue={currentDeclarante ? currentDeclarante.DeclaranteId : ''}
              fullWidth
              hidden
              disabled={currentDeclarante?.isView}
            />
            <TextField
              margin="dense"
              label="Código Declarante"
              name="CodigoDeclarante"
              defaultValue={currentDeclarante ? currentDeclarante.CodigoDeclarante : ''}
              fullWidth
              disabled={currentDeclarante?.isView}
            />
            <TextField
              margin="dense"
              label="CPF"
              name="CPF"
              defaultValue={currentDeclarante ? currentDeclarante.CPF : ''}
              fullWidth
              disabled={currentDeclarante?.isView}
            />
            <TextField
              margin="dense"
              label="Nome"
              name="NomeDeclarante"
              defaultValue={currentDeclarante ? currentDeclarante.NomeDeclarante : ''}
              fullWidth
              disabled={currentDeclarante?.isView}
            />
            <TextField
              margin="dense"
              label="Celular"
              name="DeclaranteCelular"
              defaultValue={currentDeclarante ? currentDeclarante.DeclaranteCelular : ''}
              fullWidth
              disabled={currentDeclarante?.isView}
            />
            <TextField
              margin="dense"
              label="Email"
              name="DeclaranteEmail"
              defaultValue={currentDeclarante ? currentDeclarante.DeclaranteEmail : ''}
              fullWidth
              disabled={currentDeclarante?.isView}
            />
            <TextField
              margin="dense"
              label="Status"
              name="StatusDeclarante"
              defaultValue={currentDeclarante ? currentDeclarante.StatusDeclarante : ''}
              fullWidth
              disabled={currentDeclarante?.isView}
            />
            <TextField
              margin="dense"
              label="Entregar DIRPF"
              name="EntregarDIRPF"
              defaultValue={currentDeclarante ? currentDeclarante.EntregarDIRPF : ''}
              fullWidth
              disabled={currentDeclarante?.isView}
            />
            <TextField
              margin="dense"
              label="Sócio Empresa"
              name="SocioEmpresa"
              defaultValue={currentDeclarante ? currentDeclarante.SocioEmpresa : ''}
              fullWidth
              disabled={currentDeclarante?.isView}
            />
            <TextField
              margin="dense"
              label="Opções Sócio"
              name="DeclaranteOpcoesSocio"
              defaultValue={currentDeclarante ? currentDeclarante.DeclaranteOpcoesSocio : ''}
              fullWidth
              disabled={currentDeclarante?.isView}
            />
            <TextField
              margin="dense"
              label="Contabilidade Declarante"
              name="ContabilidadeDeclarante"
              defaultValue={currentDeclarante ? currentDeclarante.ContabilidadeDeclarante : ''}
              fullWidth
              disabled={currentDeclarante?.isView}
            />
            <TextField
              margin="dense"
              label="Responsável"
              name="usuarios"
              defaultValue={currentDeclarante ? currentDeclarante.usuarios : ''}
              fullWidth
              disabled={currentDeclarante?.isView}
            />
            <TextField
              margin="dense"
              label="Data de Nascimento"
              name="dataNascimento"
              defaultValue={currentDeclarante ? currentDeclarante.dataNascimento : ''}
              fullWidth
              disabled={currentDeclarante?.isView}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="secondary">
              Fechar
            </Button>
            {!currentDeclarante?.isView && (
              <Button type="submit" color="primary">
                Salvar
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={showAddModal} onClose={handleCloseAddModal}>
        <DialogTitle>Adicionar Declarante</DialogTitle>
        <form onSubmit={handleAdd}>
          <DialogContent>
            <TextField margin="dense" label="Código Declarante" name="CodigoDeclarante" fullWidth required />
            <TextField margin="dense" label="CPF" name="CPF" fullWidth required />
            <TextField margin="dense" label="Nome" name="NomeDeclarante" fullWidth required />
            <TextField margin="dense" label="Celular" name="DeclaranteCelular" fullWidth />
            <TextField margin="dense" label="Email" name="DeclaranteEmail" fullWidth />
            <TextField margin="dense" label="Status" name="StatusDeclarante" fullWidth required />
            <TextField margin="dense" label="Entregar DIRPF" name="EntregarDIRPF" fullWidth required />
            <TextField margin="dense" label="Sócio Empresa" name="SocioEmpresa" fullWidth />
            <TextField margin="dense" label="Opções Sócio" name="DeclaranteOpcoesSocio" fullWidth />
            <TextField margin="dense" label="Contabilidade Declarante" name="ContabilidadeDeclarante" fullWidth />
            <TextField margin="dense" label="Responsável" name="usuarios" fullWidth required />
            <TextField
              margin="dense"
              label="Data de Nascimento"
              name="dataNascimento"
              fullWidth
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddModal} color="secondary">
              Fechar
            </Button>
            <Button type="submit" color="primary">
              Adicionar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={showDeleteModal} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>Tem certeza que deseja excluir este declarante?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="primary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Exportar e Configurações">
        <Fab color="primary" aria-label="settings" onClick={handleMenuOpen} style={{ position: 'fixed', bottom: 16, right: 16 }}>
          <FileDownload />
        </Fab>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => { handleExportPDF(); handleMenuClose(); }}>
          <FileDownload fontSize="small" />
          <ListItemText primary="Exportar PDF" />
        </MenuItem>
        <MenuItem onClick={() => { handleExportExcel(); handleMenuClose(); }}>
          <FileDownload fontSize="small" />
          <ListItemText primary="Exportar Excel" />
        </MenuItem>
        <MenuItem onClick={() => { handlePrint(); handleMenuClose(); }}>
          <Print fontSize="small" />
          <ListItemText primary="Imprimir" />
        </MenuItem>
      </Menu>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Declarante;
