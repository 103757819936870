import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, List, ListItem, ListItemText } from '@mui/material';
import './Suporte.css';

const Suporte = () => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [assunto, setAssunto] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [erro, setErro] = useState('');
  const [historico, setHistorico] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!nome || !email || !assunto || !mensagem) {
      setErro('Por favor, preencha todos os campos.');
      return;
    }

    const data = { nome, email, assunto, mensagem };
    
    try {
      const response = await fetch('https://www.irpratico.com.br/api/suporte.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.success) {
        alert('Mensagem enviada com sucesso!');
        setNome('');
        setEmail('');
        setAssunto('');
        setMensagem('');
        setErro('');
        fetchHistorico();
      } else {
        alert('Erro ao enviar mensagem: ' + result.message);
      }
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao enviar mensagem.');
    }
  };

  const fetchHistorico = async () => {
    try {
      const response = await fetch('https://www.irpratico.com.br/api/historico.php', {
        method: 'GET',
      });

      const result = await response.json();
      if (result.success) {
        setHistorico(result.data);
      } else {
        console.error('Erro ao carregar histórico: ' + result.message);
      }
    } catch (error) {
      console.error('Erro ao carregar histórico:', error);
    }
  };

  useEffect(() => {
    fetchHistorico();
  }, []);

  return (
    <Box className="suporte-container">
      <Box className="suporte-header">
        <Typography variant="h4">
          Suporte
        </Typography>
        <Typography variant="body1">
          Se você está enfrentando problemas ou tem alguma dúvida, por favor, preencha o formulário abaixo e nossa equipe de suporte entrará em contato com você o mais breve possível.
        </Typography>
      </Box>
      {erro && (
        <Typography variant="body2" color="error" gutterBottom>
          {erro}
        </Typography>
      )}
      <Box className="suporte-grid">
        <Box className="suporte-form">
          <Typography variant="h5" className="suporte-form-title">
            Formulário de Suporte
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Nome"
              variant="outlined"
              fullWidth
              margin="normal"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              className="text-field"
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="text-field"
            />
            <TextField
              label="Assunto"
              variant="outlined"
              fullWidth
              margin="normal"
              value={assunto}
              onChange={(e) => setAssunto(e.target.value)}
              className="text-field"
            />
            <TextField
              label="Mensagem"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              value={mensagem}
              onChange={(e) => setMensagem(e.target.value)}
              className="text-field"
            />
            <Button variant="contained" color="primary" type="submit">
              Enviar
            </Button>
          </form>
        </Box>
        <Box className="historico-container">
          <Typography variant="h5" className="historico-title">
            Histórico de Mensagens Enviadas
          </Typography>
          <List>
            {historico.slice(0, 5).map((item, index) => (
              <ListItem key={index} className="list-item">
                <ListItemText
                  primary={`Nome: ${item.nome} - Assunto: ${item.assunto}`}
                  secondary={`Data: ${item.data_envio} - Mensagem: ${item.mensagem}`}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default Suporte;
