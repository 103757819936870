import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Select,
  Button,
  Autocomplete,
  Chip,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import { useDrop } from 'react-dnd';
import KanbanCard from './KanbanCard';
import ItemType from './ItemType';

const KanbanColumn = ({ coluna, tarefas, moveCard, declarantes, statusAtividades, usuarios, setSnackbarMessage, setSnackbarOpen, updateTarefa, deleteTarefa }) => {
  const [, drop] = useDrop({
    accept: ItemType.CARD,
    drop: item => moveCard(item.id, coluna.status),
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDeclarantes, setSelectedDeclarantes] = useState([]);
  const [selectedStatusAtividade, setSelectedStatusAtividade] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleClearFilters = () => {
    setSelectedUsers([]);
    setSelectedDeclarantes([]);
    setSelectedStatusAtividade('');
    setSelectedYear('');
    setStartDate('');
    setEndDate('');
  };

  const filteredDeclarantes = declarantes.filter(declarante =>
    tarefas.some(tarefa => tarefa.id_declarante === declarante.Id && tarefa.status_tarefa === coluna.status)
  );

  const filteredUsuarios = usuarios.filter(usuario =>
    tarefas.some(tarefa => tarefa.id_usuario === usuario.id && tarefa.status_tarefa === coluna.status)
  );

  const handleUserChange = (event, value) => {
    setSelectedUsers(value);
  };

  const handleDeclaranteChange = (event, value) => {
    setSelectedDeclarantes(value);
  };

  const handleStatusAtividadeChange = (event) => {
    setSelectedStatusAtividade(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const filteredTarefas = tarefas.filter(tarefa => {
    const tarefaAno = new Date(tarefa.data_exercicio).getFullYear().toString();
    const matchesUsers = selectedUsers.length ? selectedUsers.some(user => tarefa.id_usuario === user.id) : true;
    const matchesDeclarantes = selectedDeclarantes.length ? selectedDeclarantes.some(declarante => tarefa.id_declarante === declarante.Id) : true;
    const matchesStatusAtividade = selectedStatusAtividade ? tarefa.status_trabalho === selectedStatusAtividade : true;
    const matchesYear = selectedYear ? tarefaAno === selectedYear : true;
    const matchesStartDate = startDate ? new Date(tarefa.data_criacao) >= new Date(startDate) : true;
    const matchesEndDate = endDate ? new Date(tarefa.data_criacao) <= new Date(endDate) : true;
    return tarefa.status_tarefa === coluna.status && matchesUsers && matchesDeclarantes && matchesStatusAtividade && matchesYear && matchesStartDate && matchesEndDate;
  });

  return (
    <Box ref={drop} className="kanban-column">
      <Box className="kanban-column-header" sx={{ backgroundColor: coluna.color }}>
        <Typography variant="h6" className="kanban-column-title">{coluna.titulo}</Typography>
        <Box className="kanban-column-count">{filteredTarefas.length}</Box>
      </Box>
      <Box className="kanban-column-content">
        {Array.isArray(filteredTarefas) && filteredTarefas.map(tarefa => (
          <KanbanCard
            key={tarefa.id_tarefa}
            tarefa={tarefa}
            moveCard={moveCard}
            declarantes={declarantes}
            statusAtividades={statusAtividades}
            usuarios={usuarios}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            updateTarefa={updateTarefa}
            deleteTarefa={deleteTarefa}
          />
        ))}
      </Box>
      <Box className="kanban-column-footer">
        <IconButton aria-label="filter" onClick={handleFilterClick} sx={{ color: '#FFA726' }}>
          <FilterListIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem>
            <Autocomplete
              multiple
              options={filteredDeclarantes}
              getOptionLabel={(option) => option.NomeDeclarante}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pesquisar Declarante"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              onChange={handleDeclaranteChange}
              value={selectedDeclarantes}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option.NomeDeclarante} {...getTagProps({ index })} />
                ))
              }
            />
          </MenuItem>
          <MenuItem>
            <Autocomplete
              multiple
              options={filteredUsuarios}
              getOptionLabel={(option) => option.nome}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pesquisar Usuário"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              onChange={handleUserChange}
              value={selectedUsers}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option.nome} {...getTagProps({ index })} />
                ))
              }
            />
          </MenuItem>
          <MenuItem>
            <FormControl fullWidth>
              <InputLabel>Status da Atividade</InputLabel>
              <Select
                value={selectedStatusAtividade}
                onChange={handleStatusAtividadeChange}
                label="Status da Atividade"
              >
                {statusAtividades.map(status => (
                  <MenuItem key={status.id_status} value={status.id_status}>
                    {status.descricao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MenuItem>
          <MenuItem>
            <TextField
              label="Ano"
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={selectedYear}
              onChange={handleYearChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MenuItem>
          <MenuItem>
            <TextField
              label="Data Inicial"
              type="date"
              variant="outlined"
              size="small"
              fullWidth
              value={startDate}
              onChange={handleStartDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MenuItem>
          <MenuItem>
            <TextField
              label="Data Final"
              type="date"
              variant="outlined"
              size="small"
              fullWidth
              value={endDate}
              onChange={handleEndDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MenuItem>
          <MenuItem>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<ClearIcon />}
              onClick={handleClearFilters}
              fullWidth
            >
              Limpar Filtros
            </Button>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default KanbanColumn;
