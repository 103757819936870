import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse,
  IconButton, Box, Divider, Tooltip
} from '@mui/material';
import {
  Dashboard as DashboardIcon, Assignment as AssignmentIcon,
  PersonAdd as PersonAddIcon, Settings as SettingsIcon, AccountCircle as AccountCircleIcon,
  HelpOutline as HelpOutlineIcon, ExitToApp as ExitToAppIcon, ArrowRight as ArrowRightIcon,
  Menu as MenuIcon
} from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import logo from './images/logo_irpratico_sistema.png';
import './Menu.css';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1, 1),
  ...theme.mixins.toolbar,
  position: 'relative',
}));

const Menu = ({ onLogout }) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [openSubMenu, setOpenSubMenu] = useState(''); // Usar uma string para controlar o submenu aberto
  const [open, setOpen] = useState(false); // Menu começa encolhido
  const [isFixedOpen, setIsFixedOpen] = useState(false); // Controla se o menu está fixo aberto ou não
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSubMenuToggle = (menu) => {
    if (!open) {
      setOpen(true);
      setTimeout(() => {
        setOpenSubMenu((prevSubMenu) => (prevSubMenu === menu ? '' : menu));
      }, 300);
    } else {
      setOpenSubMenu((prevSubMenu) => (prevSubMenu === menu ? '' : menu));
    }
  };

  const handleNavigation = (path, menuItem) => {
    setSelectedMenuItem(menuItem);
    navigate(path);
    if (!isFixedOpen) {
      setTimeout(() => {
        setOpen(false);
      }, 500); // Fecha o menu após a navegação
    }
  };

  const toggleDrawer = () => {
    setIsFixedOpen(!isFixedOpen);
    setOpen(!open);
  };

  return (
    <Drawer
      sx={{
        width: open ? drawerWidth : `calc(${theme.spacing(7)} + 1px)`,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: open ? drawerWidth : `calc(${theme.spacing(7)} + 1px)`,
          transition: theme.transitions.create(['width', 'background-color'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          backgroundColor: theme.palette.primary.main,
          color: '#ffffff',
          overflowX: 'hidden',
        },
      }}
      variant="permanent"
      open={open}
    >
      <DrawerHeader>
        <IconButton
          onClick={toggleDrawer}
          sx={{
            color: 'white',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
      </DrawerHeader>
      <Box sx={{ textAlign: 'center', padding: open ? '7px 0' : '1px 0' }}>
        <img src={logo} alt="Logo" className="menu-logo" />
      </Box>
      <Divider />
      <List className="menu-list" sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <ListItem button selected={selectedMenuItem === 'dashboard'} onClick={() => handleNavigation('/dashboard', 'dashboard')}>
          <Tooltip title="Dashboard" placement="right" arrow>
            <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
              <DashboardIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Dashboard" sx={{ display: open ? 'block' : 'none' }} />
        </ListItem>

        {/* Declarações Menu */}
        <ListItem button onClick={() => handleSubMenuToggle('declarações')}>
          <Tooltip title="Declarações" placement="right" arrow>
            <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
              <AssignmentIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Declarações" sx={{ display: open ? 'block' : 'none' }} />
        </ListItem>
        <Collapse in={openSubMenu === 'declarações'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button selected={selectedMenuItem === 'kanban'} onClick={() => handleNavigation('/kanban', 'kanban')} sx={{ pl: open ? 4 : 2 }}>
              <Tooltip title="Entregas" placement="right" arrow>
                <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
                  <ArrowRightIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Entregas" sx={{ display: open ? 'block' : 'none' }} />
            </ListItem>
            <ListItem button selected={selectedMenuItem === 'gerartarefas'} onClick={() => handleNavigation('/GerarTarefas', 'gerartarefas')} sx={{ pl: open ? 4 : 2 }}>
              <Tooltip title="Gerar Tarefas" placement="right" arrow>
                <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
                  <ArrowRightIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Gerar Tarefas" sx={{ display: open ? 'block' : 'none' }} />
            </ListItem>
          </List>
        </Collapse>

        {/* Cadastros Menu */}
        <ListItem button onClick={() => handleSubMenuToggle('cadastros')}>
          <Tooltip title="Cadastros" placement="right" arrow>
            <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
              <PersonAddIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Cadastros" sx={{ display: open ? 'block' : 'none' }} />
        </ListItem>
        <Collapse in={openSubMenu === 'cadastros'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button selected={selectedMenuItem === 'declarante'} onClick={() => handleNavigation('/declarante', 'declarante')} sx={{ pl: open ? 4 : 2 }}>
              <Tooltip title="Declarante" placement="right" arrow>
                <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
                  <ArrowRightIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Declarante" sx={{ display: open ? 'block' : 'none' }} />
            </ListItem>
            <ListItem button selected={selectedMenuItem === 'empresa'} onClick={() => handleNavigation('/empresa', 'empresa')} sx={{ pl: open ? 4 : 2 }}>
              <Tooltip title="Empresa" placement="right" arrow>
                <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
                  <ArrowRightIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Empresa" sx={{ display: open ? 'block' : 'none' }} />
            </ListItem>
          </List>
        </Collapse>

        {/* Configurações Menu */}
        <ListItem button onClick={() => handleSubMenuToggle('configurações')}>
          <Tooltip title="Configurações" placement="right" arrow>
            <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
              <SettingsIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Configurações" sx={{ display: open ? 'block' : 'none' }} />
        </ListItem>
        <Collapse in={openSubMenu === 'configurações'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button selected={selectedMenuItem === 'statusdeclarante'} onClick={() => handleNavigation('/statusdeclarante', 'statusdeclarante')} sx={{ pl: open ? 4 : 2 }}>
              <Tooltip title="Status Declarante" placement="right" arrow>
                <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
                  <ArrowRightIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Status Declarante" sx={{ display: open ? 'block' : 'none' }} />
            </ListItem>
            <ListItem button selected={selectedMenuItem === 'statusempresa'} onClick={() => handleNavigation('/statusempresa', 'statusempresa')} sx={{ pl: open ? 4 : 2 }}>
              <Tooltip title="Status Empresa" placement="right" arrow>
                <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
                  <ArrowRightIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Status Empresa" sx={{ display: open ? 'block' : 'none' }} />
            </ListItem>
            <ListItem button selected={selectedMenuItem === 'honorarios'} onClick={() => handleNavigation('/honorarios', 'honorarios')} sx={{ pl: open ? 4 : 2 }}>
              <Tooltip title="Honorários" placement="right" arrow>
                <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
                  <ArrowRightIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Honorários" sx={{ display: open ? 'block' : 'none' }} />
            </ListItem>
            <ListItem button selected={selectedMenuItem === 'statustarefa'} onClick={() => handleNavigation('/statustarefa', 'statustarefa')} sx={{ pl: open ? 4 : 2 }}>
              <Tooltip title="Status da Tarefa" placement="right" arrow>
                <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
                  <ArrowRightIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Status da Tarefa" sx={{ display: open ? 'block' : 'none' }} />
            </ListItem>
            <ListItem button selected={selectedMenuItem === 'dataprazos'} onClick={() => handleNavigation('/DatasPrazos', 'dataprazos')} sx={{ pl: open ? 4 : 2 }}>
              <Tooltip title="Datas e Prazos" placement="right" arrow>
                <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
                  <ArrowRightIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Datas e Prazos" sx={{ display: open ? 'block' : 'none' }} />
            </ListItem>
            <ListItem button selected={selectedMenuItem === 'contabilidade'} onClick={() => handleNavigation('/contabilidade', 'contabilidade')} sx={{ pl: open ? 4 : 2 }}>
              <Tooltip title="Escritorio" placement="right" arrow>
                <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
                  <ArrowRightIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Escritorio" sx={{ display: open ? 'block' : 'none' }} />
            </ListItem>
            <ListItem button selected={selectedMenuItem === 'usuarios'} onClick={() => handleNavigation('/usuarios', 'usuarios')} sx={{ pl: open ? 4 : 2 }}>
              <Tooltip title="Usuários" placement="right" arrow>
                <ListItemIcon sx={{ color: 'white', justifyContent: 'center' }}>
                  <ArrowRightIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Usuários" sx={{ display: open ? 'block' : 'none' }} />
            </ListItem>
          </List>
        </Collapse>
      </List>
      <Box className="menu-footer" sx={{ paddingBottom: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
  <Tooltip title="Perfil" placement="top" arrow>
    <ListItem button selected={selectedMenuItem === 'perfil'} onClick={() => handleNavigation('/perfil', 'perfil')}>
      <ListItemIcon sx={{ color: 'orange', justifyContent: 'center' }}>
        <AccountCircleIcon />
      </ListItemIcon>
    </ListItem>
  </Tooltip>
  <Tooltip title="Suporte" placement="top" arrow>
    <ListItem button selected={selectedMenuItem === 'suporte'} onClick={() => handleNavigation('/suporte', 'suporte')}>
      <ListItemIcon sx={{ color: 'orange', justifyContent: 'center' }}>
        <HelpOutlineIcon />
      </ListItemIcon>
    </ListItem>
  </Tooltip>
  <Tooltip title="Sair" placement="top" arrow>
    <ListItem button onClick={onLogout}>
      <ListItemIcon sx={{ color: 'orange', justifyContent: 'center' }}>
        <ExitToAppIcon />
      </ListItemIcon>
    </ListItem>
  </Tooltip>
</Box>

    </Drawer>
  );
};

export default Menu;
