import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDrag, useDrop } from 'react-dnd';
import ItemType from './ItemType';

const KanbanCard = ({ tarefa, moveCard, declarantes, statusAtividades, usuarios, setSnackbarMessage, setSnackbarOpen, updateTarefa, deleteTarefa }) => {
  const [, ref] = useDrag({
    type: ItemType.CARD,
    item: { id: tarefa.id_tarefa, status: tarefa.status_tarefa },
  });

  const [, drop] = useDrop({
    accept: ItemType.CARD,
    hover(item) {
      if (item.id !== tarefa.id_tarefa) {
        moveCard(item.id, tarefa.status_tarefa);
        item.status = tarefa.status_tarefa;
      }
    },
  });

  const declaranteNome = declarantes.find(declarante => declarante.Id === tarefa.id_declarante)?.NomeDeclarante || 'Desconhecido';
  const usuarioNome = usuarios.find(usuario => usuario.id === tarefa.id_usuario)?.nome || 'Desconhecido';

  const [modalOpen, setModalOpen] = useState(false);
  const [statusTrabalho, setStatusTrabalho] = useState(tarefa.status_trabalho);
  const [usuarioId, setUsuarioId] = useState(tarefa.id_usuario);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleStatusChange = (event) => {
    setStatusTrabalho(event.target.value);
  };

  const handleUsuarioChange = (event) => {
    setUsuarioId(event.target.value);
  };

  const handleSave = () => {
    updateTarefa(tarefa.id_tarefa, tarefa.status_tarefa, statusTrabalho, usuarioId);
    setModalOpen(false);
  };

  const statusAtividadeDescricao = statusAtividades.find(status => status.id_status === tarefa.status_trabalho)?.descricao || 'Desconhecido';

  return (
    <>
      <Paper ref={node => ref(drop(node))} className="kanban-card">
        <Box className="kanban-card-content">
          <Typography variant="body2" sx={{ fontWeight: 600 }}>Declarante: {declaranteNome}</Typography>
          <Typography variant="body2" sx={{ margin: '4px 0', color: '#757575' }}>
            Status de Atividade: {statusAtividadeDescricao}
          </Typography>
          <Typography variant="body2" sx={{ color: '#757575' }}>Ano Base: {new Date(tarefa.data_exercicio).getFullYear()}</Typography>
          <Typography variant="body2" sx={{ color: '#757575' }}>Data de Criação: {new Date(tarefa.data_criacao).toLocaleString('pt-BR')}</Typography>
          <Typography variant="body2" sx={{ color: '#757575' }}>Data de Exercício: {tarefa.data_exercicio}</Typography>
          <Typography variant="body2" sx={{ color: '#757575' }}>Data Selecionada: {tarefa.data_selecionada}</Typography>
          <Typography variant="body2" sx={{ color: '#757575' }}>Dia de Entrega: {tarefa.dia_entrega}</Typography>
          <Typography variant="body2" sx={{ color: '#757575' }}>Usuário: {usuarioNome}</Typography>
        </Box>
        <IconButton aria-label="edit" onClick={handleOpenModal} className="edit-icon">
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={() => deleteTarefa(tarefa.id_tarefa)} className="delete-icon">
          <DeleteIcon />
        </IconButton>
      </Paper>

      <Modal open={modalOpen} onClose={handleCloseModal} className="modal-center">
        <Box className="modal-style">
          <Typography variant="h6" component="h2">Editar Tarefa</Typography>
          <TextField label="Declarante" value={declaranteNome} fullWidth margin="normal" disabled />
          <TextField label="Status" value={tarefa.status_tarefa} fullWidth margin="normal" disabled />
          <FormControl fullWidth margin="normal">
            <InputLabel>Status da Atividade</InputLabel>
            <Select
              value={statusTrabalho}
              onChange={handleStatusChange}
              label="Status da Atividade"
            >
              {statusAtividades.map(status => (
                <MenuItem key={status.id_status} value={status.id_status}>
                  {status.descricao}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Usuário</InputLabel>
            <Select
              value={usuarioId}
              onChange={handleUsuarioChange}
              label="Usuário"
            >
              {usuarios.map(usuario => (
                <MenuItem key={usuario.id} value={usuario.id}>
                  {usuario.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button onClick={handleSave} variant="contained" color="primary" sx={{ mt: 2 }}>Salvar</Button>
        </Box>
      </Modal>
    </>
  );
};

export default KanbanCard;
