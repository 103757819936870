// src/components/Layout/Layout.js
import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import Menu from '../Menu'; // Certifique-se de ajustar o caminho para o Menu.js

const Layout = ({ children }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <CssBaseline />
    <Header />
    <Box sx={{ display: 'flex', flex: 1 }}>
      <Menu />
      <Box component="main" sx={{ flexGrow: 1, p: 3, pb: 8 }}>
        {children}
      </Box>
    </Box>
    <Footer />
  </Box>
);

export default Layout;
