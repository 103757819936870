import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const Honorarios = () => {
  const [honorarios, setHonorarios] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [currentHonorario, setCurrentHonorario] = useState(null);
  const [descricao, setDescricao] = useState('');
  const [valor, setValor] = useState('');

  useEffect(() => {
    fetchHonorarios();
  }, []);

  const fetchHonorarios = () => {
    fetch('https://node.irpratico.com.br/honorarios', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => response.json())
      .then(data => setHonorarios(data))
      .catch(error => console.error('Erro ao buscar honorários:', error));
  };

  const handleShowModal = (honorario) => {
    setCurrentHonorario(honorario);
    setDescricao(honorario ? honorario.descricao : '');
    setValor(honorario && !isNaN(honorario.valor) ? parseFloat(honorario.valor).toFixed(2).replace('.', ',') : '');
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentHonorario(null);
    setDescricao('');
    setValor('');
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    fetchHonorarios();
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const valorNumerico = parseFloat(valor.replace(",", ".").replace(/[^0-9.]/g, ''));
    const honorarioData = {
      descricao: descricao,
      valor: valorNumerico
    };

    try {
      let url = 'https://node.irpratico.com.br/honorarios';
      let method = 'POST';
      if (currentHonorario) {
        honorarioData.id = currentHonorario.id;
        url = `https://node.irpratico.com.br/honorarios/${currentHonorario.id}`;
        method = 'PUT';
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(honorarioData),
      });

      if (!response.ok) {
        throw new Error((await response.json()).message || 'Erro ao salvar honorário');
      }

      const data = await response.json();
      setHonorarios((prevHonorarios) => {
        if (currentHonorario) {
          return prevHonorarios.map((honorario) =>
            honorario.id === data.id ? data : honorario
          );
        } else {
          return [...prevHonorarios, data];
        }
      });

      setSuccessMessage(currentHonorario ? 'Honorário editado com sucesso' : 'Honorário adicionado com sucesso');
      setShowSuccessModal(true);
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao salvar honorário:', error);
      alert(`Erro ao salvar honorário: ${error.message}`);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://node.irpratico.com.br/honorarios/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error((await response.json()).message || 'Erro ao remover honorário');
      }

      setHonorarios((prevHonorarios) => prevHonorarios.filter((honorario) => honorario.id !== id));
      setSuccessMessage('Honorário removido com sucesso');
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Erro ao remover honorário:', error);
      alert(`Erro ao remover honorário: ${error.message}`);
    }
  };

  const handleConfirm = (message, action) => {
    setConfirmMessage(message);
    setConfirmAction(() => action);
    setShowConfirmModal(true);
  };

  const handleConfirmModalClose = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmModalAction = () => {
    if (confirmAction) {
      confirmAction();
    }
    setShowConfirmModal(false);
  };

  const handleValorChange = (event) => {
    const inputValue = event.target.value.replace(/[^\d,]/g, '');
    setValor(inputValue);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <h2>Honorários</h2>
      <Button variant="contained" color="primary" onClick={() => handleShowModal(null)} startIcon={<AddIcon />}>
        Adicionar Honorário
      </Button>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Descrição</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {honorarios.map((honorario) => (
              <TableRow key={honorario.id}>
                <TableCell>{honorario.descricao}</TableCell>
                <TableCell>{`R$ ${parseFloat(honorario.valor).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleShowModal(honorario)}><EditIcon /></IconButton>
                  <IconButton color="secondary" onClick={() => handleConfirm('Tem certeza que deseja remover este honorário?', () => handleDelete(honorario.id))}><DeleteIcon /></IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>{currentHonorario ? 'Editar Honorário' : 'Adicionar Honorário'}</DialogTitle>
        <form onSubmit={handleSave}>
          <DialogContent>
            <TextField
              margin="dense"
              label="Descrição"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="Valor"
              value={valor}
              onChange={handleValorChange}
              fullWidth
              required
              InputProps={{
                startAdornment: <span>R$ </span>,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="secondary">Fechar</Button>
            <Button type="submit" color="primary">Salvar</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar open={showSuccessModal} autoHideDuration={6000} onClose={handleSuccessModalClose}>
        <Alert onClose={handleSuccessModalClose} severity="success">{successMessage}</Alert>
      </Snackbar>

      <Dialog open={showConfirmModal} onClose={handleConfirmModalClose}>
        <DialogTitle>Confirmação</DialogTitle>
        <DialogContent>{confirmMessage}</DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmModalClose} color="secondary">Cancelar</Button>
          <Button onClick={handleConfirmModalAction} color="primary">Confirmar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Honorarios;
