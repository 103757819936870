import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
} from '@mui/material';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import TableViewIcon from '@mui/icons-material/TableView';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import KanbanColumn from './KanbanColumn';
import KanbanTable from './KanbanTable';
import './Kanban.css';

const kanbanColumns = [
  { status: 'Leads', titulo: 'Leads', color: '#1976D2' },
  { status: 'Não Fazer', titulo: 'Não Fazer', color: '#FFA726' },
  { status: 'A Fazer', titulo: 'A Fazer', color: '#42A5F5' },
  { status: 'Executando', titulo: 'Executando', color: '#FF7043' },
  { status: 'Transmitida', titulo: 'Transmitida', color: '#26C6DA' },
];

const Kanban = () => {
  const [tarefas, setTarefas] = useState([]);
  const [declarantes, setDeclarantes] = useState([]);
  const [statusAtividades, setStatusAtividades] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState('kanban');

  useEffect(() => {
    fetch('https://node.irpratico.com.br/kanban_controle')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setTarefas(data.tarefas || []);
        setDeclarantes(data.declarantes || []);
        setStatusAtividades(data.statusAtividades || []);
        setUsuarios(data.usuarios || []);
        setLoading(false);
      })
      .catch(error => {
        console.error('Erro ao buscar tarefas:', error);
        setLoading(false);
      });
  }, []);

  const updateTarefa = (id_tarefa, status_tarefa, status_trabalho, id_usuario) => {
    fetch('https://node.irpratico.com.br/kanban_controle/update_status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id_tarefa, status_tarefa, status_trabalho, id_usuario }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      if (data.success) {
        setTarefas(prevTarefas => prevTarefas.map(tarefa => {
          if (tarefa.id_tarefa === id_tarefa) {
            return { ...tarefa, status_trabalho, id_usuario };
          }
          return tarefa;
        }));
        setSnackbarMessage('Tarefa atualizada com sucesso!');
      } else {
        setSnackbarMessage('Erro ao atualizar a tarefa.');
      }
      setSnackbarOpen(true);
    })
    .catch(error => {
      console.error('Erro ao atualizar a tarefa:', error);
      setSnackbarMessage('Erro ao atualizar a tarefa.');
      setSnackbarOpen(true);
    });
  };

  const deleteTarefa = (id_tarefa) => {
    fetch('https://node.irpratico.com.br/kanban_controle/delete_tarefa', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id_tarefa }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      if (data.success) {
        setTarefas(prevTarefas => prevTarefas.filter(tarefa => tarefa.id_tarefa !== id_tarefa));
        setSnackbarMessage('Tarefa excluída com sucesso!');
      } else {
        setSnackbarMessage('Erro ao excluir a tarefa.');
      }
      setSnackbarOpen(true);
    })
    .catch(error => {
      console.error('Erro ao excluir a tarefa:', error);
      setSnackbarMessage('Erro ao excluir a tarefa.');
      setSnackbarOpen(true);
    });
  };

  const moveCard = (id, newStatus) => {
    const updatedTarefas = tarefas.map(tarefa => {
      if (tarefa.id_tarefa === id) {
        return { ...tarefa, status_tarefa: newStatus };
      }
      return tarefa;
    });
    setTarefas(updatedTarefas);

    fetch('https://node.irpratico.com.br/kanban_controle/update_status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id_tarefa: id, status_tarefa: newStatus }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (data.success) {
          setSnackbarMessage('Status da tarefa atualizado com sucesso!');
        } else {
          setSnackbarMessage('Erro ao atualizar status da tarefa.');
        }
        setSnackbarOpen(true);
      })
      .catch(error => {
        console.error('Erro ao atualizar status:', error);
        setSnackbarMessage('Erro ao atualizar status da tarefa.');
        setSnackbarOpen(true);
      });
  };

  return (
    <Box className="kanban-container">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => window.location.href = '/GerarTarefas'}
        >
          Adicionar Declaração
        </Button>
        <Box>
          <IconButton onClick={() => setViewMode('kanban')}>
            <ViewKanbanIcon color={viewMode === 'kanban' ? 'primary' : 'default'} />
          </IconButton>
          <IconButton onClick={() => setViewMode('table')}>
            <TableViewIcon color={viewMode === 'table' ? 'primary' : 'default'} />
          </IconButton>
        </Box>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : viewMode === 'kanban' ? (
        <DndProvider backend={HTML5Backend}>
          <Box className="kanban-board">
            {kanbanColumns.map(coluna => (
              <KanbanColumn
                key={coluna.status}
                coluna={coluna}
                tarefas={tarefas}
                moveCard={moveCard}
                declarantes={declarantes}
                statusAtividades={statusAtividades}
                usuarios={usuarios}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarOpen={setSnackbarOpen}
                updateTarefa={updateTarefa}
                deleteTarefa={deleteTarefa}
              />
            ))}
          </Box>
        </DndProvider>
      ) : (
        <KanbanTable
          tarefas={tarefas}
          declarantes={declarantes}
          statusAtividades={statusAtividades}
          usuarios={usuarios}
          updateTarefa={updateTarefa}
          deleteTarefa={deleteTarefa}
        />
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Kanban;
