import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme/theme'; // Corrigido o caminho
import Login from './components/Login';
import Dashboard from './components/Dashboard/Dashboard';
import AdminDashboard from './components/Admin/AdminDashboard';
import ManagerDashboard from './components/Manager/ManagerDashboard';
import Declarante from './components/Cadastros/Declarante';
import StatusDeclarante from './components/Configuracoes/StatusDeclarante'; // Corrigido o caminho
import Honorarios from './components/Configuracoes/Honorarios'; // Corrigido o caminho
import StatusTarefa from './components/Configuracoes/StatusTarefa'; // Corrigido o caminho
import DatasPrazos from './components/Configuracoes/DatasPrazos';
import Contabilidade from './components/Configuracoes/Contabilidade';
import Usuarios from './components/Configuracoes/Usuarios';
import Kanban from './components/Declaracoes/Kanban'; // Corrigido o caminho
import GerarTarefas from './components/Declaracoes/GerarTarefas';
import ListaEntregas from './components/Declaracoes/ListaEntregas';
import Perfil from './components/Perfil';
import Suporte from './components/Suporte'; 
import Empresa from './components/Cadastros/Empresa';
import StatusEmpresa from './components/Configuracoes/StatusEmpresa';
import Layout from './components/Layout/Layout'; // Importando o Layout
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setIsAuthenticated(true);
      setUserId(user.id);
    }
  }, []);

  const handleLogin = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
    setIsAuthenticated(true);
    setUserId(user.id);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    setIsAuthenticated(false);
    setUserId(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        {isAuthenticated ? (
          <Layout>
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/admin" element={<AdminDashboard />} />
              <Route path="/manager" element={<ManagerDashboard />} />
              <Route path="/declarante" element={<Declarante />} />
              <Route path="/empresa" element={<Empresa />} />
              <Route path="/statusdeclarante" element={<StatusDeclarante />} />
              <Route path="/honorarios" element={<Honorarios />} />
              <Route path="/statustarefa" element={<StatusTarefa />} />
              <Route path="/DatasPrazos" element={<DatasPrazos />} />
              <Route path="/contabilidade" element={<Contabilidade />} />
              <Route path="/usuarios" element={<Usuarios />} />
              <Route path="/GerarTarefas" element={<GerarTarefas />} />
              <Route path="/ListaEntregas" element={<ListaEntregas />} />
              <Route path="/Perfil" element={<Perfil userId={userId} />} />
              <Route path="/kanban" element={<Kanban />} />
              <Route path="/suporte" element={<Suporte />} />
              <Route path="/StatusEmpresa" element={<StatusEmpresa />} />
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
            </Routes>
          </Layout>
        ) : (
          <Routes>
            <Route path="/" element={<Login onLogin={handleLogin} />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )}
      </Router>
    </ThemeProvider>
  );
}

export default App;
