import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
 Snackbar,
  Alert
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { format, parseISO, isValid } from 'date-fns';

const DatasPrazos = () => {
  const [dataPrazos, setDataPrazos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [message, setMessage] = useState('');
  const [messageSeverity, setMessageSeverity] = useState('success');
  const [currentDataPrazo, setCurrentDataPrazo] = useState(null);
  const [dataExercicio, setDataExercicio] = useState('');
  const [dataSelecionada, setDataSelecionada] = useState('');
  const [diaEntrega, setDiaEntrega] = useState('');

  useEffect(() => {
    fetchDataPrazos();
  }, []);

  const fetchDataPrazos = () => {
    fetch('https://node.irpratico.com.br/datas_prazos', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => response.json())
      .then(data => {
        const formattedData = data.map(item => ({
          ...item,
          data_selecionada: item.data_selecionada ? format(parseISO(item.data_selecionada), 'dd/MM/yyyy') : '',
          dia_entrega: item.dia_entrega ? format(parseISO(item.dia_entrega), 'dd/MM/yyyy') : '',
        }));
        setDataPrazos(formattedData);
      })
      .catch(error => {
        setMessage('Erro ao buscar datas e prazos: ' + error.message);
        setMessageSeverity('error');
        setShowMessageModal(true);
      });
  };

  const handleShowModal = (dataPrazo) => {
    setCurrentDataPrazo(dataPrazo);
    setDataExercicio(dataPrazo ? dataPrazo.data_exercicio : '');
    setDataSelecionada(dataPrazo && isValid(parseISO(dataPrazo.data_selecionada)) ? format(parseISO(dataPrazo.data_selecionada), 'yyyy-MM-dd') : '');
    setDiaEntrega(dataPrazo && isValid(parseISO(dataPrazo.dia_entrega)) ? format(parseISO(dataPrazo.dia_entrega), 'yyyy-MM-dd') : '');
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentDataPrazo(null);
    setDataExercicio('');
    setDataSelecionada('');
    setDiaEntrega('');
  };

  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    fetchDataPrazos();
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const dataPrazo = {
      data_exercicio: dataExercicio,
      data_selecionada: dataSelecionada ? format(parseISO(dataSelecionada), 'yyyy-MM-dd') : null,
      dia_entrega: diaEntrega ? format(parseISO(diaEntrega), 'yyyy-MM-dd') : null,
    };

    try {
      let url = 'https://node.irpratico.com.br/datas_prazos';
      let method = 'POST';
      if (currentDataPrazo) {
        url = `https://node.irpratico.com.br/datas_prazos/${currentDataPrazo.id}`;
        method = 'PUT';
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(dataPrazo),
      });

      if (!response.ok) {
        throw new Error((await response.json()).message || 'Erro ao salvar data e prazo');
      }

      const data = await response.json();
      if (currentDataPrazo) {
        setDataPrazos(dataPrazos.map(dp => (dp.id === data.id ? {
          ...data,
          data_selecionada: format(parseISO(data.data_selecionada), 'dd/MM/yyyy'),
          dia_entrega: format(parseISO(data.dia_entrega), 'dd/MM/yyyy')
        } : dp)));
        setMessage('Data e prazo editados com sucesso');
      } else {
        setDataPrazos([...dataPrazos, {
          ...data,
          data_selecionada: format(parseISO(data.data_selecionada), 'dd/MM/yyyy'),
          dia_entrega: format(parseISO(data.dia_entrega), 'dd/MM/yyyy')
        }]);
        setMessage('Data e prazo adicionados com sucesso');
      }
      setMessageSeverity('success');
      setShowMessageModal(true);
      handleCloseModal();
    } catch (error) {
      setMessage('Erro ao salvar data e prazo: ' + error.message);
      setMessageSeverity('error');
      setShowMessageModal(true);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Tem certeza que deseja remover esta data e prazo?')) {
      try {
        const response = await fetch(`https://node.irpratico.com.br/datas_prazos/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          throw new Error((await response.json()).message || 'Erro ao remover data e prazo');
        }

        setDataPrazos(dataPrazos.filter(dp => dp.id !== id));
        setMessage('Data e prazo removidos com sucesso');
        setMessageSeverity('success');
        setShowMessageModal(true);
      } catch (error) {
        setMessage('Erro ao remover data e prazo: ' + error.message);
        setMessageSeverity('error');
        setShowMessageModal(true);
      }
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'data_exercicio', headerName: 'Ano base', flex: 2 },
    { field: 'data_selecionada', headerName: 'Data de antecipação', flex: 2 },
    { field: 'dia_entrega', headerName: 'Dia de entrega', flex: 2 },
    {
      field: 'actions',
      headerName: 'Ações',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton color="primary" onClick={() => handleShowModal(params.row)}><EditIcon /></IconButton>
          <IconButton color="secondary" onClick={() => handleDelete(params.row.id)}><DeleteIcon /></IconButton>
        </>
      )
    }
  ];

  return (
    <Box p={2}>
      <h2>Configurações de Datas e Prazos</h2>
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => handleShowModal(null)}>
        Adicionar Data e Prazo
      </Button>
      <Box mt={2} style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={dataPrazos}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </Box>

      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>{currentDataPrazo ? 'Editar Data e Prazo' : 'Adicionar Data e Prazo'}</DialogTitle>
        <form onSubmit={handleSave}>
          <DialogContent>
            <TextField
              margin="dense"
              label="Ano base"
              value={dataExercicio}
              onChange={(e) => setDataExercicio(e.target.value)}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              label="Data de antecipação"
              type="date"
              value={dataSelecionada}
              onChange={(e) => setDataSelecionada(e.target.value)}
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              margin="dense"
              label="Dia de entrega"
              type="date"
              value={diaEntrega}
              onChange={(e) => setDiaEntrega(e.target.value)}
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="secondary">Fechar</Button>
            <Button type="submit" color="primary">Salvar</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar open={showMessageModal} autoHideDuration={6000} onClose={handleCloseMessageModal}>
        <Alert onClose={handleCloseMessageModal} severity={messageSeverity}>{message}</Alert>
      </Snackbar>
    </Box>
  );
};

export default DatasPrazos;
