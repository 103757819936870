import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Snackbar,
  Alert,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { ChromePicker } from 'react-color';

const StatusAtividade = () => {
  const [statuses, setStatuses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [message, setMessage] = useState('');
  const [messageSeverity, setMessageSeverity] = useState('success');
  const [currentStatus, setCurrentStatus] = useState(null);
  const [descricao, setDescricao] = useState('');
  const [cor, setCor] = useState('');

  useEffect(() => {
    fetchStatuses();
  }, []);

  const fetchStatuses = () => {
    fetch('https://node.irpratico.com.br/status_atividade', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => response.json())
      .then(data => {
        setStatuses(data);
      })
      .catch(error => {
        setMessage('Erro ao buscar status: ' + error.message);
        setMessageSeverity('error');
        setShowMessageModal(true);
      });
  };

  const handleShowModal = (status) => {
    setCurrentStatus(status);
    setDescricao(status ? status.descricao : '');
    setCor(status ? status.cor : '');
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentStatus(null);
    setDescricao('');
    setCor('');
  };

  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    fetchStatuses();
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const statusAtividade = { descricao, cor };

    try {
      let url = 'https://node.irpratico.com.br/status_atividade';
      let method = 'POST';
      if (currentStatus) {
        url = `https://node.irpratico.com.br/status_atividade/${currentStatus.id_status}`;
        method = 'PUT';
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(statusAtividade),
      });

      if (!response.ok) {
        throw new Error((await response.json()).message || 'Erro ao salvar status de atividade');
      }

      const data = await response.json();
      if (currentStatus) {
        setStatuses(statuses.map(status => (status.id_status === data.id_status ? data : status)));
        setMessage('Status editado com sucesso');
      } else {
        setStatuses([...statuses, data]);
        setMessage('Status adicionado com sucesso');
      }
      setMessageSeverity('success');
      setShowMessageModal(true);
      handleCloseModal();
    } catch (error) {
      setMessage('Erro ao salvar status de atividade: ' + error.message);
      setMessageSeverity('error');
      setShowMessageModal(true);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Tem certeza que deseja remover este status?')) {
      try {
        const response = await fetch(`https://node.irpratico.com.br/status_atividade/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          throw new Error((await response.json()).message || 'Erro ao remover status de atividade');
        }

        setStatuses(statuses.filter(status => status.id_status !== id));
        setMessage('Status removido com sucesso');
        setMessageSeverity('success');
        setShowMessageModal(true);
      } catch (error) {
        setMessage('Erro ao remover status de atividade: ' + error.message);
        setMessageSeverity('error');
        setShowMessageModal(true);
      }
    }
  };

  return (
    <Box p={2}>
      <Typography variant="h5" gutterBottom>Status da Tarefa</Typography>
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => handleShowModal(null)}>
        Adicionar Status
      </Button>
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Cor</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statuses.map((status) => (
                <TableRow key={status.id_status}>
                  <TableCell>{status.id_status}</TableCell>
                  <TableCell>{status.descricao}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        width: '50px',
                        height: '25px',
                        backgroundColor: status.cor,
                        borderRadius: '5%'
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleShowModal(status)}><EditIcon /></IconButton>
                    <IconButton color="secondary" onClick={() => handleDelete(status.id_status)}><DeleteIcon /></IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>{currentStatus ? 'Editar Status' : 'Adicionar Status'}</DialogTitle>
        <form onSubmit={handleSave}>
          <DialogContent>
            <TextField
              margin="dense"
              label="Descrição"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
              fullWidth
              required
            />
            <Box mt={2}>
              <Typography variant="body1" gutterBottom>
                Cor
              </Typography>
              <ChromePicker
                color={cor}
                onChangeComplete={(color) => setCor(color.hex)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="secondary">Fechar</Button>
            <Button type="submit" color="primary">Salvar</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar open={showMessageModal} autoHideDuration={6000} onClose={handleCloseMessageModal}>
        <Alert onClose={handleCloseMessageModal} severity={messageSeverity}>{message}</Alert>
      </Snackbar>
    </Box>
  );
};

export default StatusAtividade;
